.b-section {
	padding: 200px 0 120px;

	@include md-tablet {
		padding: 160px 0 100px; }

	@include md-tablet_sm {
		padding: 140px 0 100px; }

	@include md-mobile {
		padding: 100px 0 60px; }

	&_style_1 {
		background-image: url( '#{$img}/b-section/bg_1.png' );
		background-size: 211px 198px;
		background-position: calc( 50% - 679px ) 267px;
		background-repeat: no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-section/bg_1@2x.png' ); } }

	&_style_2,
	&_style_3 {
		position: relative;
		padding-bottom: 190px;
		margin-bottom: -$b-footer-m_t;

		@include md-tablet {
			padding-bottom: 165px; }

		@include md-mobile {
			padding-bottom: 130px; }

		&::before,
		&::after {

			@include md-tablet {
				display: none; } }

		&::before {
			content: '';

			position: absolute;
			left: 0;
			bottom: 0;

			width: 100%;
			height: 960px;

			background: linear-gradient( 180deg, rgba( $grey-lightest, 0 ) 0%, rgba( $grey-lightest, .8 ) 100% ); }

		&::after {
			content: '';

			position: absolute;
			left: 0;

			width: 100%;

			background-image: url( '#{$img}/b-section/bg_2.png' ), url( '#{$img}/b-section/bg_3.png' ), url( '#{$img}/b-section/bg_4.png' ), url( '#{$img}/b-section/bg_5.png' ), url( '#{$img}/b-section/bg_6.png' ), url( '#{$img}/b-section/bg_7.png' ), url( '#{$img}/b-section/bg_8.png' ), url( '#{$img}/b-section/bg_9.png' );
			background-size: 174px 140px, 90px 90px, 87px 100px, 210px 172px, 162px 134px, 110px 105px, 59px 59px, 169px 74px;
			background-position: calc( 50% - 950px ) 46px, calc( 50% + 678px ) 0, calc( 50% - 636px ) 139px, calc( 50% + 936px ) 341px, calc( 50% - 454px ) 577px, calc( 50% + 664px ) 863px, calc( 50% - 671px ) 998px, calc( 50% + 362px ) 993px;
			background-repeat: no-repeat;

			@include md-retina {
				background-image: url( '#{$img}/b-section/bg_2@2x.png' ), url( '#{$img}/b-section/bg_3@2x.png' ), url( '#{$img}/b-section/bg_4@2x.png' ), url( '#{$img}/b-section/bg_5@2x.png' ), url( '#{$img}/b-section/bg_6@2x.png' ), url( '#{$img}/b-section/bg_7@2x.png' ), url( '#{$img}/b-section/bg_8@2x.png' ), url( '#{$img}/b-section/bg_9@2x.png' ); } }

		> * {
			position: relative;
			z-index: 1; } } // to be over &::before and &::after

	&_style_2::after {
		top: 135px;
		height: calc( 100% - 135px ); }

	&_style_3::after {
		top: 385px;
		height: calc( 100% - 385px );
		background-position: calc( 50% - 950px ) 46px, calc( 50% + 678px ) 0, calc( 50% - 636px ) 139px, calc( 50% + 936px ) 341px, calc( 50% - 454px ) 577px, calc( 50% + 664px ) 863px, calc( 50% - 671px ) 998px, calc( 50% + 362px ) 1124px; } }
