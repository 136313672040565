.b-faq {
	max-width: 830px;
	margin: 0 auto;

	&__box {
		position: relative;
		min-height: 90px;
		margin-bottom: 14px;

		@include md-mobile {
			min-height: 78px;
			margin-bottom: 16px; }

		&:last-child {
			margin-bottom: 0; }

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 25px;

			@include md-tablet_sm {
				border-radius: 20px; }

			@include md-mobile {
				border-radius: 17px; } }

		&::before {
			top: 15px;
			left: 15px;

			width: calc( 100% - 30px );
			height: calc( 100% - 15px );

			box-shadow: 0px 15px 35px rgba( $b_sh_c, .15 );

			@include md-mobile {
				box-shadow: 0px 10px 25px rgba( $b_sh_c, .15 ); } }

		&::after {
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: $white;
			box-shadow: inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 20px rgba( $b_sh_c_inner, .17 );

			@include md-mobile {
				box-shadow: inset 1px 1px 0px rgba( $white, .5 ), inset -10px -10px 15px rgba( $b_sh_c_inner, .17 ); } } }

	&__box-top {
		padding: 38px 30px 38px 100px;

		@include md-mobile {
			padding: 33px 25px 33px 76px; }

		&::before {
			content: '';

			position: absolute;
			left: 23px;
			top: 18px;
			z-index: 1; // to be above &__box::before and &__box::after

			width: 54px;
			height: 54px;

			border-radius: 50%;

			background: $white;
			box-shadow: inset 7px 7px 10px rgba( $b_sh_c_inner, .12 );

			@include md-mobile {
				left: 15px;
				top: 16px;

				width: 46px;
				height: 46px; } } }

	&__heading {
		position: relative;
		z-index: 1; // to be above &__box::before and &__box::after

		margin: -7px 0 -8px;

		color: $black;
		font-size: 19px;
		line-height: 29px;
		font-weight: 500;
		letter-spacing: .04em;

		@include md-mobile {
			margin: -6px 0 -7px;
			font-size: 17px;
			line-height: 25px; } }

	&__box-content {
		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-top: -8px;
		padding: 0 30px 38px 100px;

		@include md-mobile {
			padding: 0 25px 33px 76px; } }

	&__text {
		position: relative;
		z-index: 1; // to be above &__box::before and &__box::after

		margin: -10px 0 -11px;

		color: $black;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: .04em;

		@include md-mobile {
			margin: -9px 0 -10px;
			font-size: 15px;
			line-height: 30px; } } }
