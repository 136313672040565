@if $_use_modifiers == true {

	._pos_relative {
		position: relative !important; }

	._pos_absolute {
		position: absolute !important; }



	._block {
		display: block !important; }

	._block_tb {

		@include md-tablet {
			display: block !important; } }

	._block_mb {

		@include md-mobile {
			display: block !important; } }



	._flex {
		display: flex !important; }

	._flex_tb {

		@include md-tablet {
			display: flex !important; } }

	._flex_mb {

		@include md-mobile {
			display: flex !important; } }



	._inline_block {
		display: inline-block !important; }

	._inline_block_tb {

		@include md-tablet {
			display: inline-block !important; } }

	._inline_block_mb {

		@include md-mobile {
			display: inline-block !important; } }



	._inline {
		display: inline !important; }

	._inline_tb {

		@include md-tablet {
			display: inline !important; } }

	._inline_mb {

		@include md-mobile {
			display: inline !important; } }



	._only_tb {
		display: none !important;

		@include md-tablet {
			display: block !important; } }

	._only_inline_block_tb {
		display: none !important;

		@include md-tablet {
			display: inline-block !important; } }

	._only_flex_tb {
		display: none !important;

		@include md-tablet {
			display: flex !important; } }

	._only_inline_tb {
		display: none !important;

		@include md-tablet {
			display: inline !important; } }



	._only_mb {
		display: none !important;

		@include md-mobile {
			display: block !important; } }

	._only_inline_block_mb {
		display: none !important;

		@include md-mobile {
			display: inline-block !important; } }

	._only_flex_mb {
		display: none !important;

		@include md-mobile {
			display: flex !important; } }

	._only_inline_mb {
		display: none !important;

		@include md-mobile {
			display: inline !important; } }



	._none {
		display: none !important; }

	._none_tb {

		@include md-tablet {
			display: none !important; } }

	._none_mb {

		@include md-mobile {
			display: none !important; } }



	._bind {
		position: relative !important; }

	._unbind {
		position: static !important; }

	._flex_horiz_center {
		justify-content: center; }

	._full_w {
		width: 100% !important; }

	._full_h {
		height: 100% !important; }

	._full_h_vh {
		height: 100vh !important; }

	._min_h_auto {
		min-height: auto !important; }

	._min_h_full_vh {
		min-height: 100vh !important; }

	._hidden {
		overflow: hidden !important; }

	._overflow_auto {
		overflow: auto !important; }

	._block_center {
		margin-left: auto !important;
		margin-right: auto !important; }

	._block_vert_center {
		margin-top: auto !important;
		margin-bottom: auto !important; }

	._letter_spacing_inherit {
		letter-spacing: inherit; }

	._text_center {
		text-align: center !important; }

	._text_left {
		text-align: left !important; }

	._text_right {
		text-align: right !important; }

	._text_capitalize {
		text-transform: capitalize !important; }

	._text_uppercase {
		text-transform: uppercase !important; }

	._text_ellipsis {

		@include text-ellipsis; }

	._nowrap {
		white-space: nowrap; }



	._cur_p {
		cursor: pointer !important; }

	._events_none {
		pointer-events: none !important; } }
