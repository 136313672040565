.b-hero {
	position: relative; // for &__down

	min-height: 1120px;

	margin-bottom: 190px - 219px;
	padding: 215px 0 180px;

	background-image: url( '#{$img}/b-hero/bg_4.png' ), url( '#{$img}/b-hero/bg_1.png' ), url( '#{$img}/b-hero/bg_2.png' ), url( '#{$img}/b-hero/bg_3.png' ), url( '#{$img}/b-hero/bg.png' );
	background-size: 868px 824px, 151px 126px, 166px 137px, 110px 105px, $md-desk_xl 1120px;
	background-position: calc( 50% + 498px ) 146px, calc( 50% + 395px ) 177px, 25px 625px, 234px 929px, 50%;
	background-repeat: no-repeat;

	@include md-retina {
		background-image: url( '#{$img}/b-hero/bg_4@2x.png' ), url( '#{$img}/b-hero/bg_1@2x.png' ), url( '#{$img}/b-hero/bg_2@2x.png' ), url( '#{$img}/b-hero/bg_3@2x.png' ), url( '#{$img}/b-hero/bg2x.png' ); }

	@include md-desk_xl {
		background-size: 868px 824px, 151px 126px, 166px 137px, 110px 105px, 100% 1120px; }

	@include md-desk_md {
		background-position: calc( 50% + 595px ) 146px, calc( 50% + 395px ) 177px, 25px 625px, 234px 929px, 50%; }

	@include md-tablet {
		min-height: 0;

		padding: 160px 0 320px;
		margin-bottom: -90px;

		background-image: url( '#{$img}/b-hero/bg_1.png' ), url( '#{$img}/b-hero/bg_2.png' ), url( '#{$img}/b-hero/bg.png' );
		background-size: 151px 126px, 166px 137px, $md-desk_xl 1120px;
		background-position: calc( 50% + 430px ) 100px, 25px 625px, 50% 100%;
		background-repeat: no-repeat;

		text-align: center;

		@include md-retina {
			background-image: url( '#{$img}/b-hero/bg_1@2x.png' ), url( '#{$img}/b-hero/bg_2@2x.png' ), url( '#{$img}/b-hero/bg@2x.png' ); } }

	@include md-tablet_sm {
		padding: 110px 0 320px; }

	@include md-mobile {
		padding: 90px 0 300px;
		margin-bottom: -120px;
		background-position: calc( 50% + 220px ) 60px, 5px 79%, 50% 100%; }

	&__content {
		max-width: 720px;
		margin-left: -50px;

		@include md-desk_lg {
			margin-left: 0; }

		@include md-desk_md {
			margin-left: 100px; }

		@include md-tablet {
			margin: 0 auto; } }

	&__img {
		max-height: 137px;
		margin-left: -2px;
		margin-bottom: 20px;

		@include md-tablet {
			margin: 0 auto 17px; }

		@include md-tablet_sm {
			max-height: 90px; } }

	&__promotion {
		position: relative; // for &::before and &::after
		margin-bottom: 40px;
		padding: 35px 0;

		@include md-tablet_sm {
			margin-bottom: 35px;
			border-radius: 40px;
			padding: 35px 30px;

			background: linear-gradient( 270deg, #FFE14D 0%, #FFEE99 100% ), #FCE972;
			box-shadow: -5px -5px 15px rgba( $white, .1), 7px 15px 25px rgba( $black, .12 ), inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 30px rgba( #B49832, .3 ); }

		@include md-mobile {
			margin-bottom: 30px;
			border-radius: 30px;
			padding: 30px 15px; }

		&_last {
			margin-bottom: 0; }

		&::before {
			content: '';

			position: absolute;
			top: -20px;
			left: -135px;

			width: 917px;
			height: 224px;

			background: url( '#{$img}/b-hero/bg_box.png' ) center / contain no-repeat;

			@include md-retina {
				background-image: url( '#{$img}/b-hero/bg_box@2x.png' ); }

			@include md-tablet_sm {
				display: none; } } }

	&__promotion-inner {
		position: relative;
		z-index: 1; // to be over &__promotion::before

		display: flex; // to collapse margins inside
		flex-direction: column;

		@extend .i-loudspeaker;

		&::before {
			content: '';

			position: absolute;
			top: -18px;
			left: -100px;

			display: block;
			flex-shrink: 0;

			width: 70px;
			height: 70px;

			margin-right: 30px;
			border-radius: 35px;

			background: $white;
			box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( $b_sh_c, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -5px -5px 10px rgba( $b_sh_c_inner, .25 );

			@include md-tablet_sm {
				display: none; } }

		&::after {
			position: absolute;
			top: 2px;
			left: -79px;

			font-size: 30px;
			color: $black;

			transform: rotateZ( -15deg );

			@include md-tablet_sm {
				display: none; } } }

	&__promotion-text {
		margin: -13px 0;

		color: $grey-dark;
		font-size: 20px;
		line-height: 40px;
		font-weight: 500;
		letter-spacing: .02em;

		@include md-tablet_sm {
			margin: -11px 0 -12px;
			font-size: 18px;
			line-height: 36px; }

		@include md-mobile {
			margin: -9px 0 -11px;
			font-size: 16px;
			line-height: 32px; } }

	&__text {
		margin: -11px 0 28px;

		color: $grey-dark;
		font-size: 16px;
		line-height: 34px;
		font-weight: 500;
		letter-spacing: .02em;

		@include md-tablet {
			margin-bottom: 27px; }

		@include md-mobile {
			margin: -9px 0 21px;
			font-size: 14px;
			line-height: 28px; }

		&_last {
			margin-bottom: -12px;

			@include md-mobile {
				margin-bottom: -9px; } } }

	&__down {
		position: absolute;
		left: calc( 50% - 40px );
		top: 860px;

		width: 80px;
		height: 80px;

		border-radius: 50%;

		background: $white;
		box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( $b_sh_c, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -5px -5px 10px rgba( $b_sh_c_inner, .25 );

		@extend .i-arrow-2;

		@include md-tablet {
			display: none; }

		&::after {
			position: absolute;
			top: 38px;
			left: 30px;

			color: $black;
			font-size: 12px; } } }
