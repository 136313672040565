.b-heading-4 {
	margin: -4px 0 46px;

	color: $black;
	font-size: 28px;
	line-height: 28px;
	font-weight: 700;
	letter-spacing: .04em;

	@include md-mobile {
		margin: -4px 0 31px; }

	&_last {
		margin-bottom: -4px; } }
