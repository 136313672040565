.-ol-input,
.-ol-textarea {
	display: block;

	width: 100%;

	padding: ( $p_vert-input + 1px ) $p_horiz-input ( $p_vert-input - 1px ); // valign for select
	border: $brd_w-input solid $brd_c-input;
	border-radius: $brd_rd-input;

	background: $bg-input;

	color: $c-input;
	font-size: $f_sz-input;
	line-height: $h-input - $brd_w-input * 2 - $p_vert-input * 2;
	letter-spacing: $l_s-input;

	text-overflow: ellipsis;

	@include placeholder-style;

	@include md-mobile {
		padding: ( $p_vert-input + 1px ) $p_horiz-input_mb ( $p_vert-input - 1px ); } // valign for select

	&[readonly],
	&[disabled] {
		color: $grey-light;
		cursor: default; } }

.-ol-input {
	height: $h-input; }

.-ol-textarea {
	height: $h-textarea;
	resize: vertical;
	overflow: auto; }

.-ol-input-file {
	position: relative;
	overflow: hidden;
	width: 100%;

	input {
		$h-input-file: 100%;

		width: 100%;
		height: $h-input-file;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;

		opacity: 0;

		cursor: pointer; } }

.-ol-checkbox {
	position: relative;

	width: $w-checkbox;
	height: $h-checkbox;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		opacity: 0;
		overflow: hidden;

		width: 100%;
		height: 100%; }

	&__trigger {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: $brd_w-checkbox solid $brd_c-checkbox;
		border-radius: $brd_rd-checkbox;

		background-color: $bg-checkbox;

		cursor: pointer; }

	&__input:checked ~ &__trigger {
		border: $brd_w-checkbox_checked solid $brd_c-checkbox_checked;
		background-color: $bg-checkbox_checked; }

	&[disabled] {
		opacity: .55;
		cursor: default; } }

.-ol-radio {
	position: relative;

	width: $w-radio;
	height: $h-radio;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		opacity: 0;
		overflow: hidden;

		width: 100%;
		height: 100%;

		&[disabled] {
			cursor: default; } }

	&__trigger {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: $brd_w-radio solid $brd_c-radio;
		border-radius: $brd_rd-radio;

		background-color: $bg-radio;

		cursor: pointer; }

	&__input:checked ~ &__trigger {
		border: $brd_w-radio_checked solid $brd_c-radio_checked;
		background-color: $bg-radio_checked; }

	&__input[disabled] ~ &__trigger {
		border-color: $grey-lighter;
		background-color: $grey-lighter; } }

.-ol-label { // tag label
	position: absolute;
	top: 26px;
	left: $p_horiz-input + $brd_w-input;
	z-index: 1;

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-bottom: $m_b-label;
	padding: 0;

	background-color: $white;

	transition: all .2s;

	pointer-events: none;

	@include md-mobile {
		left: $p_horiz-input_mb + $brd_w-input; }

	&_last {
		margin-bottom: 0; }

	input:focus ~ &,
	._filled ~ & {
		top: -9px;
		left: $p_horiz-input + $brd_w-input - 10px;
		padding: 0 10px;

		@include md-mobile {
			left: $p_horiz-input_mb + $brd_w-input - 10px; } } }

.-ol-label-text { // tag span
	display: block;

	margin: -5px 0 -7px;
	color: $c-label;
	font-size: $f_sz-label;
	line-height: 24px;
	font-weight: $f_w-label;
	letter-spacing: $l_s-input;

	transition: all .2s;

	input:focus ~ .-ol-label &,
	._filled ~ .-ol-label & {
		font-weight: 600;
		font-size: 14px;
		line-height: 28px; } }

.-ol-input-link {
	position: absolute;
	top: 26px;
	right: $p_horiz-input;

	display: block;

	margin: -5px 0 -7px;
	color: $brand-primary;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: .04em;

	@include md-mobile {
		right: $p_horiz-input_mb; } }

.-ol-input-error {
	position: absolute;
	bottom: calc( 100% + 5px );
	right: 0;

	border-radius: 5px;
	padding: 12px 20px 11px;

	background-color: $brand-danger;

	color: $white;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: .04em;
	text-align: right;

	@include md-mobile {
		padding: 6px 15px;
		font-size: 13px;
		line-height: 23px; } }

.-ol-input-wrap {
	position: relative;

	&_disabled {
		opacity: .6;

		.-ol-input,
		.-ol-textarea {
			color: $c-placeholder; }

		label {
			cursor: auto; } }

	&_error {

		.-ol-input,
		.-ol-textarea {
			border-color: $brd_c-form-error;
			color: $c-form-error; }

		.-ol-label-text {
			color: $c-form-error; } }

	&_select {

		.-ol-input {
			padding-right: $p_horiz-ol-input-arrow-type * 2 + $brd_w-input + $d-ol-input-arrow-type + 10px;

			@include md-mobile {
				padding-right: $p_horiz-ol-input-arrow-type_mb * 2 + $brd_w-input + $d-ol-input-arrow-type + 10px; } }

		&::before {
			content: '';

			position: absolute;
			top: 30px;
			right: $p_horiz-ol-input-arrow-type + $brd_w-input;

			border: $d-ol-input-arrow-type solid transparent;
			border-top-color: $black;
			border-bottom-width: 0;

			@include md-mobile {
				right: $p_horiz-ol-input-arrow-type_mb + $brd_w-input; } } } }

.-ol-row-input {
	margin-bottom: $m-row-input;

	@include md-mobile {
		margin-bottom: $m-row-input-mb; }

	&_last {
		margin-bottom: 0; } }

.-ol-row-input-mb {

	@include md-mobile {
		margin-bottom: $m-row-input-mb; } }

.-ol-form-body {
	margin-bottom: $m-form-body;

	@include md-mobile {
		margin-bottom: $m-form-body_mb; }

	&_last {
		margin-bottom: 0; } }
