.b-list-blocks {
	display: flex; // to collapse margins inside
	flex-direction: column;
	margin-bottom: 30px;

	&_last,
	&_last_if_last:last-child {
		margin-bottom: 0; }

	&__inner {
		display: flex;
		margin: -15px;

		@include md-mobile {
			margin: -10px; } }

	&_wrap &__inner {
		flex-wrap: wrap; }

	&_col_mb &__inner {

		@include md-mobile {
			flex-direction: column; } }

	&_side_by_side &__inner {
		justify-content: space-between; }

	&_right &__inner {
		justify-content: flex-end; }

	&_equal &__inner {
		flex-wrap: nowrap; }

	&_valign &__inner {
		align-items: center; }

	&__item {
		margin: 15px;

		@include md-mobile {
			margin: 10px; } }

	&_equal &__item {
		flex: 1 1 100%;
		overflow: hidden; } }
