.b-footer {
	$h-bg_top: 110px;

	position: relative; // for &::before

	overflow: hidden; // to prevent horizontal scroll

	padding: 100px 0 60px;

	background-image: url( '#{$img}/b-footer/bg.png' );
	background-size: $md-desk_xl $h-bg_top;
	background-position: center top;
	background-repeat: no-repeat;

	@include md-retina {
		background-image: url( '#{$img}/b-footer/bg@2x.png' ); }

	@include md-desk_xl {
		background-size: 100% $h-bg_top; }

	@include md-tablet_sm {
		padding-bottom: 40px; }

	@include md-mobile {
		padding-top: 85px;
		text-align: center; }

	&::before {
		content: '';

		position: absolute;
		top: $h-bg_top;
		left: 0;

		width: 100%;
		height: calc( 100% - #{ $h-bg_top } );

		background-color: $black; }

	&::after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: url( '#{$img}/b-footer/bg_1.png' ), url( '#{$img}/b-footer/bg_2.png' ), url( '#{$img}/b-footer/bg_3.png' ), url( '#{$img}/b-footer/bg_4.png' ), url( '#{$img}/b-footer/bg_5.png' );
		background-size: 100px 100px, 81px 86px, 88px 89px, 60px 60px, 115px 115px;
		background-position: calc( 50% - 212px ) 28px, calc( 55% - 735px ) 64px, calc( 50% + 637px ) 92px, calc( 50% - 960px ) 189px, calc( 50% + 117px ) 212px;
		background-repeat: no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-footer/bg_1@2x.png' ), url( '#{$img}/b-footer/bg_2@2x.png' ), url( '#{$img}/b-footer/bg_3@2x.png' ), url( '#{$img}/b-footer/bg_4@2x.png' ), url( '#{$img}/b-footer/bg_5@2x.png' ); }

		@include md-mobile {
			display: none; } }

	&__inner {
		position: relative;
		z-index: 1; } // to be over &&::after

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		margin: -15px -30px 25px -30px;

		@include md-tablet_sm {
			margin: -15px -25px 25px -25px; }

		@include md-mobile {
			justify-content: center; }

		&_text {
			align-items: baseline; }

		&:last-child {
			margin-bottom: -15px; } }

	&__item {
		margin: 15px 30px;

		@include md-tablet_sm {
			margin: 15px 25px; } }

	&__logo {

		@extend .i-logo;

		&::after {
			color: rgba( $white, .3 );
			font-size: 38px; } }

	&__nav {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		margin: -12px -30px;

		@include md-tablet_sm {
			margin: -12px -25px; } }

	&__nav-item {
		margin: 12px 30px;

		@include md-tablet_sm {
			margin: 12px 25px; } }

	&__nav-link { // tag a
		display: block;

		margin: -6px 0 -7px;

		color: rgba( $white, .5 );
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		letter-spacing: .06em;

		transition: color .2s;

		&_active,
		&:hover {
			color: $white; }

		&_active {
			pointer-events: none; } }

	&__btns {
		display: flex; // to collapse margins inside
		flex-direction: column; }

	&__btns-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: -10px -15px; }

	&__btns-item {
		margin: 10px 15px; }

	&__btn { // tag a
		position: relative; // &::before

		display: block;

		overflow: hidden;

		border-radius: 6px;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			z-index: 1; // to be over &__btn-img

			width: 100%;
			height: 100%;

			border-radius: 6px;

			box-shadow: inset 1px 1px 0px rgba( $white, .35 ), -3px -3px 15px rgba( $white, .2 ), 5px 5px 15px rgba( $black, .15 );

			transition: box-shadow .2s; }

		&:hover::before {
			box-shadow: inset 1px 1px 0px rgba( $white, .25 ), -3px -3px 15px rgba( $white, .1 ), 5px 5px 15px rgba( $black, .05 ); }

		&:active::before {
			box-shadow: inset 1px 1px 0px rgba( $white, .15 ), -3px -3px 15px rgba( $white, 0 ), 5px 5px 15px rgba( $black, 0 ); } }

	&__btn-img {
		height: 60px; }

	&__text {
		margin: -6px 0 -7px;

		color: rgba( $white, .5 );
		font-size: 14px;
		line-height: 24px;
		font-style: italic;
		letter-spacing: .06em; } }
