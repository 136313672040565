@mixin link-variant ( $c: $c-link, $brd_c: $brd_c-link, $c_hover: $c-link_hover, $brd_c_hover: $brd_c-link_hover ) {

	display: inline;
	border-bottom: $brd_w-link solid $brd_c;

	color: $c;
	line-height: inherit;
	letter-spacing: inherit;

	cursor: pointer;

	transition: all .2s;

	&:hover {
		border-bottom-color: $brd_c_hover;
		color: $c_hover; } }
