@mixin btn-variant( $c-btn, $bg_c-btn, $per-hover: $per-btn_hover, $per-active: $per-btn_active, $brd_c-btn: $bg_c-btn ) {

	border-color: $brd_c-btn;
	background: $bg_c-btn; // to set gradient
	color: $c-btn;

	transition: all .2s;

	&:hover {
		border-color: darken( $brd_c-btn, $per-hover );
		background: darken( $bg_c-btn, $per-hover );
		color: $c-btn; }

	&:active {
		border-color: darken( $brd_c-btn, $per-hover + $per-active );
		background: darken( $bg_c-btn, $per-hover + $per-active );
		color: $c-btn; } } // to set default style



@mixin btn-link( $brd_b_c-btn, $c-btn, $brd_b_c-btn_hover, $c-btn_hover ) {

	border-color: transparent;
	border-bottom: $brd_w solid $brd_b_c-btn;
	color: $c-btn;

	&:hover {
		border-bottom-color: $brd_b_c-btn_hover;
		color: $c-btn_hover; } }



// Button sizes
@mixin btn-size( $h-btn, $brd_rd-btn, $p_vert-btn, $p_horiz-btn, $f_sz-btn, $lh-btn, $brd_w-btn ) {

	height: $h-btn;
	min-height: $h-btn; // for empty buttons (without text)

	border-radius: $brd_rd-btn;
	border-width: $brd_w-btn;
	padding: $p_vert-btn $p_horiz-btn;

	font-size: $f_sz-btn;
	line-height: $lh-btn; }
