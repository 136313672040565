@if $_use_root == true {

	*:root {

		/*----------- colors -----------*/

		/*----------- main -----------*/

		--black:					$black;
		--grey-darker:				$grey-darker;
		--grey-dark:				$grey-dark;
		--grey:						$grey;
		--grey-light:				$grey-light;
		--grey-lighter:				$grey-lighter;
		--white:					$white;

		--brand-primary:			$brand-primary;
		--brand-success:			$brand-success;
		--brand-info:				$brand-info;
		--brand-warning:			$brand-warning;
		--brand-danger:				$brand-danger;

		/*----------- main (end) -----------*/



		/*----------- common -----------*/

		--bg_c-body:				$bg_c-body;
		--brd_c:					$brd_c;

		/*----------- common (end) -----------*/

		/*----------- colors (end) -----------*/







		/*----------- options -----------*/

		/*----------- media -----------*/

		--md-desk_md:				$md-desk_md;
		--md-desk_sm:				$md-desk_sm;
		--md-tablet:				$md-tablet;
		--md-mobile:				$md-mobile;
		--md-mobile_sm:				$md-mobile_sm;

		/*----------- media (end) -----------*/

		/*----------- options (end) -----------*/ } }
