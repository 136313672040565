.b-get-started {
	margin: -60px 0 ( -$b-footer-m_t );
	padding: 280px 0 140px;

	background-image: url( '#{$img}/b-get-started/bg_1.png' ), url( '#{$img}/b-get-started/bg_2.png' ), url( '#{$img}/b-get-started/bg.png' );
	background-size: 193px 158px, 162px 134px, $md-desk_xl calc( 100% -  60px );
	background-position: calc( 50% + 713px ) 1px, calc( 50% - 697px ) 247px, 50% 60px;
	background-repeat: no-repeat;

	text-align: center;

	@include md-retina {
		background-image: url( '#{$img}/b-get-started/bg_1@2x.png' ), url( '#{$img}/b-get-started/bg_2@2x.png' ), url( '#{$img}/b-get-started/bg2x.png' ); }

	@include md-desk_xl {
		background-size: 193px 158px, 162px 134px, 100% calc( 100% -  60px );
		background-position: calc( 50% + 713px ) 1px, calc( 50% - 697px ) 247px, 50% 60px; }

	@include md-tablet_sm {
		margin: 0 0 ( -$b-footer-m_t );
		padding: 120px 0 130px;

		background-image: url( '#{$img}/b-get-started/bg.png' );
		background-size: $md-desk_xl 100%;
		background-position: 50% 0;

		@include md-retina {
			background-image: url( '#{$img}/b-get-started/bg@2x.png' ); } }

	@include md-mobile {
		margin-top: -30px;
		padding: 115px 0; }

	&__heading {
		display: flex; // to collapse margins inside
		flex-direction: column;
		margin-bottom: 45px;

		@include md-tablet_sm {
			margin-bottom: 40px; }

		@include md-mobile {
			margin-bottom: 30px; }

		&_last {
			margin-bottom: 0; } }

	&__title {
		margin: -11px 0 21px;

		color: $black;
		font-size: 48px;
		line-height: 56px;
		font-weight: 700;
		letter-spacing: .02em;
		text-shadow: -1px 1px 1px rgba( $white, .5 );

		@include md-tablet_sm {
			margin: -11px 0 17px;
			font-size: 42px;
			line-height: 50px; }

		@include md-mobile {
			margin: -10px 0 16px;
			font-size: 36px;
			line-height: 44px; }

		&_last {
			margin-bottom: -11px;

			@include md-tablet_sm {
				margin-bottom: -10px; }

			@include md-mobile {
				margin-bottom: -9px; } } }

	&__subtitle {
		margin: -11px 0;

		color: $black;
		font-size: 34px;
		line-height: 46px;
		font-weight: 700;
		letter-spacing: .02em;
		text-shadow: -1px 1px 1px rgba( $white, .5 );

		@include md-tablet_sm {
			margin: -9px 0 -11px;
			font-size: 30px;
			line-height: 42px; }

		@include md-mobile {
			margin: -8px 0 -7px;
			font-size: 26px;
			line-height: 34px; } } }
