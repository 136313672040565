.b-heading-3 {
	margin: -7px 0 17px;

	color: $black;
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
	letter-spacing: .04em;

	text-transform: uppercase;

	&_m_b_lg {
		margin-bottom: 27px; }

	&_last {
		margin-bottom: -8px; } }
