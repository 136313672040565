/* settings */
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* main */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/sprite/icomoon.eot?xavgft");
  src: url("../fonts/sprite/icomoon.eot?xavgft#iefix") format("embedded-opentype"), url("../fonts/sprite/icomoon.ttf?xavgft") format("truetype"), url("../fonts/sprite/icomoon.woff?xavgft") format("woff"), url("../fonts/sprite/icomoon.svg?xavgft#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* core */
/* mixins */
/**
 * this style blurs the images in Safari
 * and some elements in Chrome (23.02.2015)
 */
/**
 * extend .-ol-clfx from
 * core -> snippets -> float.less
 */
/* base */
*:root {
  /*----------- colors -----------*/
  /*----------- main -----------*/
  --black:					$black;
  --grey-darker:				$grey-darker;
  --grey-dark:				$grey-dark;
  --grey:						$grey;
  --grey-light:				$grey-light;
  --grey-lighter:				$grey-lighter;
  --white:					$white;
  --brand-primary:			$brand-primary;
  --brand-success:			$brand-success;
  --brand-info:				$brand-info;
  --brand-warning:			$brand-warning;
  --brand-danger:				$brand-danger;
  /*----------- main (end) -----------*/
  /*----------- common -----------*/
  --bg_c-body:				$bg_c-body;
  --brd_c:					$brd_c;
  /*----------- common (end) -----------*/
  /*----------- colors (end) -----------*/
  /*----------- options -----------*/
  /*----------- media -----------*/
  --md-desk_md:				$md-desk_md;
  --md-desk_sm:				$md-desk_sm;
  --md-tablet:				$md-tablet;
  --md-mobile:				$md-mobile;
  --md-mobile_sm:				$md-mobile_sm;
  /*----------- media (end) -----------*/
  /*----------- options (end) -----------*/ }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
hr,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
button,
fieldset, form, label, legend, textarea, input, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-smoothing: antialiased; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

iframe, svg {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

img,
video {
  display: block;
  max-width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  text-align: left; }

/* remember to define focus styles! */
*:focus {
  outline: 0; }

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none; }

/* remove indent in Firefox */
button,
input,
textarea,
select,
a {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
a {
  letter-spacing: inherit;
  word-spacing: inherit;
  cursor: pointer; }

button {
  display: block;
  overflow: visible;
  color: inherit; }

button,
input[type='submit'],
input[type='reset'],
input[type='file'] {
  text-align: left; }

input {
  word-break: normal; }
  input[type="checkbox"], input[type="radio"] {
    cursor: pointer; }
  input[type="color"] {
    border: none;
    outline: none;
    -webkit-appearance: none; }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-moz-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-webkit-color-swatch {
      border: none; }
    input[type="color"]::-moz-color-swatch {
      border: none; }

input,
textarea,
select {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

label {
  cursor: pointer; }

a {
  color: inherit;
  text-decoration: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

pre {
  white-space: pre-wrap; }

/* remove indent in Firefox */
@-moz-document url-prefix() {
  button::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    margin: 0;
    border: 0;
    padding: 0; } }

.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  font-weight: 600;
  letter-spacing: 0.06em;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 8px;
  border-width: 0;
  padding: 18px 25px;
  font-size: 16px;
  line-height: 24px;
  border-color: #000;
  background: #000;
  color: #fff;
  transition: all .2s; }
  .-ol-btn:hover {
    border-color: black;
    background: black;
    color: #fff; }
  .-ol-btn:active {
    border-color: black;
    background: black;
    color: #fff; }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #1551ED;
    transition: all .2s; }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #1551ED; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #1551ED; }
  .-ol-btn_success {
    border-color: #58c067;
    background: #58c067;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #46b956;
      background: #46b956;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #3fa64e;
      background: #3fa64e;
      color: #fff; }
  .-ol-btn_info {
    border-color: #69c1df;
    background: #69c1df;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #54b8db;
      background: #54b8db;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #3fb0d6;
      background: #3fb0d6;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #FFC224;
    background: #FFC224;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #ffbb0b;
      background: #ffbb0b;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #f0ad00;
      background: #f0ad00;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #FF383B;
    background: #FF383B;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #ff1f22;
      background: #ff1f22;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #ff0509;
      background: #ff0509;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #1551ED;
    background: transparent;
    color: #1551ED;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #1148d8;
      background: rgba(0, 0, 0, 0);
      color: #1551ED; }
    .-ol-btn_outline:active {
      border-color: #0f40c0;
      background: rgba(0, 0, 0, 0);
      color: #1551ED; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #0F81EA; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#0F81EA, 20%);
      color: #0F81EA; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 8px;
    border-width: 0;
    padding: 17px 30px;
    font-size: 2rem;
    line-height: 30px; }
  .-ol-btn_sm {
    height: auto;
    min-height: auto;
    border-radius: 6px;
    border-width: 0;
    padding: 13px 20px;
    font-size: 16px;
    line-height: 24px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 4px;
    border-width: 0;
    padding: 9.5px 15px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }

*::selection {
  background: #1551ED;
  color: #fff; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  font-variant-ligatures: no-common-ligatures !important;
  font-size: 10px;
  text-size-adjust: 100%;
  line-height: 1.2;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  word-break: normal;
  word-break: break-word; }

body {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #fff;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  font-weight: 400;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (max-width: 640px) {
    body {
      min-height: auto; } }
  body._no_scroll {
    height: 100%;
    overflow: hidden; }
  body._mobile_test {
    width: 320px; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  border: 1px solid #E2E3EC;
  border-radius: 8px;
  padding: 6px 29px;
  background: transparent;
  color: #2F3238;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.04em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #8C8C8C;
    cursor: default; }

.-ol-input {
  height: 64px; }

.-ol-textarea {
  height: 160px;
  resize: vertical;
  overflow: auto; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 0; }
  .-ol-label_last {
    margin-bottom: 0; }

.-ol-label-text {
  display: block;
  color: #2F3238;
  font-size: 16px;
  font-weight: 400; }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #2F3238; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    border-color: #FF383B;
    color: #FF383B; }

.-ol-row-input {
  margin-bottom: 30px; }
  .-ol-row-input_last {
    margin-bottom: 0; }

.-ol-form-body {
  margin-bottom: 40px; }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-table {
  width: 100%;
  text-align: left; }
  .-ol-table td,
  .-ol-table th {
    padding: 10px 10px;
    vertical-align: middle; }
  .-ol-table thead th {
    font-size: 1.1em;
    font-weight: 600; }
  .-ol-table_even tbody tr:nth-child(2n) {
    background: #f2f2f2; }
  .-ol-table_odd tbody tr:nth-child(2n-1) {
    background: #f2f2f2; }

.-ol-thead {
  border-color: #ebebeb;
  border-style: solid;
  border-bottom-width: 1px; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #0F81EA;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #0c67ba;
    color: #0c67ba; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

/* snippets */
.-ol-container {
  position: relative;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px; }
  @media only screen and (max-width: 1230px) {
    .-ol-container {
      width: 100%;
      max-width: none;
      padding: 0 20px; } }
  .-ol-container_full_w {
    width: 100%;
    max-width: none;
    padding: 0 30px; }
    @media only screen and (max-width: 1230px) {
      .-ol-container_full_w {
        padding: 0 20px; } }

.-ol-clfx::before, .-ol-clfx::after {
  content: "";
  display: table; }

.-ol-clfx::after {
  clear: both; }

.-ol-row,
.-ol-row-float,
.-ol-row-inline,
.-ol-row-table {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1230px) {
    .-ol-row,
    .-ol-row-float,
    .-ol-row-inline,
    .-ol-row-table {
      margin-left: -10px;
      margin-right: -10px; } }

.-ol-row {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.-ol-row-inline {
  position: relative;
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .-ol-row-inline > .-ol-col {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .-ol-row-inline > .-ol-col_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.-ol-row-table {
  position: relative;
  display: table;
  height: 100%;
  width: calc( 100% + 30px);
  table-layout: fixed; }
  .-ol-row-table > .-ol-col_table {
    display: table-cell;
    vertical-align: top; }
  .-ol-row-table > .-ol-col_table_major {
    width: 100%; }

.-ol-row-float {
  position: relative; }
  .-ol-row-float::before, .-ol-row-float::after {
    content: "";
    display: table; }
  .-ol-row-float::after {
    clear: both; }
  .-ol-row-float > [class*='-ol-col'] {
    float: left; }

.-ol-row_without_inside_space {
  margin: 0; }

[class*=-ol-col-] {
  position: relative;
  vertical-align: top;
  padding: 0 15px; }
  @media only screen and (max-width: 1230px) {
    [class*=-ol-col-] {
      width: 100%;
      padding: 0 10px; } }

.-ol-col_without_inside_space {
  padding: 0; }

.-ol-col_valign_m {
  vertical-align: middle !important; }

.-ol-col_valign_b {
  vertical-align: bottom !important; }

[class*=-ol-col-offset] {
  margin-left: 0; }

@media only screen and (min-width: 1231px) {
  .-ol-col-0 {
    width: 0%; }
  .-ol-col-offset-0 {
    margin-left: 0%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-1 {
    width: 8.33333%; }
  .-ol-col-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-2 {
    width: 16.66667%; }
  .-ol-col-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-3 {
    width: 25%; }
  .-ol-col-offset-3 {
    margin-left: 25%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-4 {
    width: 33.33333%; }
  .-ol-col-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-5 {
    width: 41.66667%; }
  .-ol-col-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-6 {
    width: 50%; }
  .-ol-col-offset-6 {
    margin-left: 50%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-7 {
    width: 58.33333%; }
  .-ol-col-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-8 {
    width: 66.66667%; }
  .-ol-col-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-9 {
    width: 75%; }
  .-ol-col-offset-9 {
    margin-left: 75%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-10 {
    width: 83.33333%; }
  .-ol-col-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-11 {
    width: 91.66667%; }
  .-ol-col-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-12 {
    width: 100%; }
  .-ol-col-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-0 {
    width: 0%; }
  .-ol-col-dm-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-1 {
    width: 8.33333%; }
  .-ol-col-dm-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-2 {
    width: 16.66667%; }
  .-ol-col-dm-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-3 {
    width: 25%; }
  .-ol-col-dm-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-4 {
    width: 33.33333%; }
  .-ol-col-dm-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-5 {
    width: 41.66667%; }
  .-ol-col-dm-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-6 {
    width: 50%; }
  .-ol-col-dm-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-7 {
    width: 58.33333%; }
  .-ol-col-dm-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-8 {
    width: 66.66667%; }
  .-ol-col-dm-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-9 {
    width: 75%; }
  .-ol-col-dm-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-10 {
    width: 83.33333%; }
  .-ol-col-dm-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-11 {
    width: 91.66667%; }
  .-ol-col-dm-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-12 {
    width: 100%; }
  .-ol-col-dm-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-0 {
    width: 0%; }
  .-ol-col-tb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-1 {
    width: 8.33333%; }
  .-ol-col-tb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-2 {
    width: 16.66667%; }
  .-ol-col-tb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-3 {
    width: 25%; }
  .-ol-col-tb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-4 {
    width: 33.33333%; }
  .-ol-col-tb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-5 {
    width: 41.66667%; }
  .-ol-col-tb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-6 {
    width: 50%; }
  .-ol-col-tb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-7 {
    width: 58.33333%; }
  .-ol-col-tb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-8 {
    width: 66.66667%; }
  .-ol-col-tb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-9 {
    width: 75%; }
  .-ol-col-tb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-10 {
    width: 83.33333%; }
  .-ol-col-tb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-11 {
    width: 91.66667%; }
  .-ol-col-tb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-12 {
    width: 100%; }
  .-ol-col-tb-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-0 {
    width: 0%; }
  .-ol-col-mb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-1 {
    width: 8.33333%; }
  .-ol-col-mb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-2 {
    width: 16.66667%; }
  .-ol-col-mb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-3 {
    width: 25%; }
  .-ol-col-mb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-4 {
    width: 33.33333%; }
  .-ol-col-mb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-5 {
    width: 41.66667%; }
  .-ol-col-mb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-6 {
    width: 50%; }
  .-ol-col-mb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-7 {
    width: 58.33333%; }
  .-ol-col-mb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-8 {
    width: 66.66667%; }
  .-ol-col-mb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-9 {
    width: 75%; }
  .-ol-col-mb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-10 {
    width: 83.33333%; }
  .-ol-col-mb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-11 {
    width: 91.66667%; }
  .-ol-col-mb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-12 {
    width: 100%; }
  .-ol-col-mb-offset-12 {
    margin-left: 100%; } }

.-ol-gap-remover {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1230px) {
    .-ol-gap-remover {
      margin: 0; } }

@media only screen and (max-width: 1230px) {
  .-ol-gap-remover-tb {
    margin-left: -10px;
    margin-right: -10px; } }

@media only screen and (max-width: 640px) {
  .-ol-gap-remover-mb {
    margin-left: -10px;
    margin-right: -10px; } }

._pos_relative {
  position: relative !important; }

._pos_absolute {
  position: absolute !important; }

._block {
  display: block !important; }

@media only screen and (max-width: 1230px) {
  ._block_tb {
    display: block !important; } }

@media only screen and (max-width: 640px) {
  ._block_mb {
    display: block !important; } }

._flex {
  display: flex !important; }

@media only screen and (max-width: 1230px) {
  ._flex_tb {
    display: flex !important; } }

@media only screen and (max-width: 640px) {
  ._flex_mb {
    display: flex !important; } }

._inline_block {
  display: inline-block !important; }

@media only screen and (max-width: 1230px) {
  ._inline_block_tb {
    display: inline-block !important; } }

@media only screen and (max-width: 640px) {
  ._inline_block_mb {
    display: inline-block !important; } }

._inline {
  display: inline !important; }

@media only screen and (max-width: 1230px) {
  ._inline_tb {
    display: inline !important; } }

@media only screen and (max-width: 640px) {
  ._inline_mb {
    display: inline !important; } }

._only_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_tb {
      display: block !important; } }

._only_inline_block_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_inline_block_tb {
      display: inline-block !important; } }

._only_flex_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_flex_tb {
      display: flex !important; } }

._only_inline_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_inline_tb {
      display: inline !important; } }

._only_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_mb {
      display: block !important; } }

._only_inline_block_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_block_mb {
      display: inline-block !important; } }

._only_flex_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_flex_mb {
      display: flex !important; } }

._only_inline_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_mb {
      display: inline !important; } }

._none {
  display: none !important; }

@media only screen and (max-width: 1230px) {
  ._none_tb {
    display: none !important; } }

@media only screen and (max-width: 640px) {
  ._none_mb {
    display: none !important; } }

._bind {
  position: relative !important; }

._unbind {
  position: static !important; }

._flex_horiz_center {
  justify-content: center; }

._full_w {
  width: 100% !important; }

._full_h {
  height: 100% !important; }

._full_h_vh {
  height: 100vh !important; }

._min_h_auto {
  min-height: auto !important; }

._min_h_full_vh {
  min-height: 100vh !important; }

._hidden {
  overflow: hidden !important; }

._overflow_auto {
  overflow: auto !important; }

._block_center {
  margin-left: auto !important;
  margin-right: auto !important; }

._block_vert_center {
  margin-top: auto !important;
  margin-bottom: auto !important; }

._letter_spacing_inherit {
  letter-spacing: inherit; }

._text_center {
  text-align: center !important; }

._text_left {
  text-align: left !important; }

._text_right {
  text-align: right !important; }

._text_capitalize {
  text-transform: capitalize !important; }

._text_uppercase {
  text-transform: uppercase !important; }

._text_ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

._nowrap {
  white-space: nowrap; }

._cur_p {
  cursor: pointer !important; }

._events_none {
  pointer-events: none !important; }

/* project */
/* mixins */
/* base */
.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  font-weight: 600;
  letter-spacing: 0.06em;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 8px;
  border-width: 0;
  padding: 18px 25px;
  font-size: 16px;
  line-height: 24px;
  border-color: #000;
  background: #000;
  color: #fff;
  transition: all .2s;
  box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.3), 5px 5px 15px rgba(255, 255, 255, 0.15), inset 2px 2px 0px rgba(255, 255, 255, 0.35); }
  .-ol-btn:hover {
    border-color: black;
    background: black;
    color: #fff; }
  .-ol-btn:active {
    border-color: black;
    background: black;
    color: #fff; }
  .-ol-btn:hover {
    box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(255, 255, 255, 0.1), inset 1px 1px 0px rgba(255, 255, 255, 0.35); }
  .-ol-btn:active {
    box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(255, 255, 255, 0.1), inset 1px 1px 0px rgba(255, 255, 255, 0.15); }
  .-ol-btn_style_2 {
    box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.15), 5px 5px 15px rgba(255, 255, 255, 0.1), inset 2px 2px 0 rgba(255, 255, 255, 0.35); }
    .-ol-btn_style_2:hover {
      box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.05), 5px 5px 15px rgba(255, 255, 255, 0.05), inset 1px 1px 0px rgba(255, 255, 255, 0.35); }
    .-ol-btn_style_2:active {
      box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.05), 5px 5px 15px rgba(255, 255, 255, 0.05), inset 1px 1px 0px rgba(255, 255, 255, 0.15); }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #1551ED;
    transition: all .2s; }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #1551ED; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #1551ED; }
  .-ol-btn_success {
    border-color: #58c067;
    background: #58c067;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #46b956;
      background: #46b956;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #3fa64e;
      background: #3fa64e;
      color: #fff; }
  .-ol-btn_info {
    border-color: #69c1df;
    background: #69c1df;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #54b8db;
      background: #54b8db;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #3fb0d6;
      background: #3fb0d6;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #FFC224;
    background: #FFC224;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #ffbb0b;
      background: #ffbb0b;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #f0ad00;
      background: #f0ad00;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #FF383B;
    background: #FF383B;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #ff1f22;
      background: #ff1f22;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #ff0509;
      background: #ff0509;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #1551ED;
    background: transparent;
    color: #1551ED;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #1148d8;
      background: rgba(0, 0, 0, 0);
      color: #1551ED; }
    .-ol-btn_outline:active {
      border-color: #0f40c0;
      background: rgba(0, 0, 0, 0);
      color: #1551ED; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #0F81EA; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#0F81EA, 20%);
      color: #0F81EA; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 8px;
    border-width: 0;
    padding: 17px 30px;
    font-size: 2rem;
    line-height: 30px; }
  .-ol-btn_sm {
    height: auto;
    min-height: auto;
    border-radius: 6px;
    border-width: 0;
    padding: 13px 20px;
    font-size: 16px;
    line-height: 24px;
    padding-top: 14px;
    padding-bottom: 12px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 4px;
    border-width: 0;
    padding: 9.5px 15px;
    font-size: 1.4rem;
    line-height: 21px; }
  .-ol-btn_w_fix_sm {
    width: 140px;
    max-width: 100%; }
  .-ol-btn_w_fix_lg {
    width: 180px;
    max-width: 100%; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }
  .-ol-btn_next {
    min-width: 180px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center; }
  .-ol-btn_next .-ol-btn__text {
    position: relative;
    padding-right: 45px;
    font-size: 14px;
    letter-spacing: .06em; }
    .-ol-btn_next .-ol-btn__text::before {
      content: '';
      position: absolute;
      top: -7px;
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.3), 5px 5px 15px rgba(0, 0, 0, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.15);
      transition: all .2s; }
    .-ol-btn_next .-ol-btn__text::after {
      position: absolute;
      top: 5px;
      right: 8px;
      color: #fff;
      font-size: 8px;
      transition: right .2s; }
  .-ol-btn_next:hover .-ol-btn__text::before {
    right: -3px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.3), 5px 5px 15px rgba(0, 0, 0, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.15); }
  .-ol-btn_next:hover .-ol-btn__text::after {
    right: 5px; }
  .-ol-btn_next:active .-ol-btn__text::before {
    right: 0; }
  .-ol-btn_next:active .-ol-btn__text::after {
    right: 9px; }

body {
  background-color: #FFFFFE; }

._modal_active {
  overflow: hidden; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  padding: 7px 29px 5px;
  border: 1px solid #E2E3EC;
  border-radius: 8px;
  background: transparent;
  color: #2F3238;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.04em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    color: #2F3238;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.04em; }
  @media only screen and (max-width: 640px) {
    .-ol-input,
    .-ol-textarea {
      padding: 7px 19px 5px; } }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #8C8C8C;
    cursor: default; }

.-ol-input {
  height: 64px; }

.-ol-textarea {
  height: 160px;
  resize: vertical;
  overflow: auto; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-checkbox {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
  .-ol-checkbox__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #E2E3EC;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer; }
  .-ol-checkbox__input:checked ~ .-ol-checkbox__trigger {
    border: 5px solid #1551ED;
    background-color: #fff; }
  .-ol-checkbox[disabled] {
    opacity: .55;
    cursor: default; }

.-ol-radio {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-radio__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .-ol-radio__input[disabled] {
      cursor: default; }
  .-ol-radio__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #E2E3EC;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer; }
  .-ol-radio__input:checked ~ .-ol-radio__trigger {
    border: 5px solid #1551ED;
    background-color: #fff; }
  .-ol-radio__input[disabled] ~ .-ol-radio__trigger {
    border-color: #E2E3EC;
    background-color: #E2E3EC; }

.-ol-label {
  position: absolute;
  top: 26px;
  left: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: 0;
  background-color: #fff;
  transition: all .2s;
  pointer-events: none; }
  @media only screen and (max-width: 640px) {
    .-ol-label {
      left: 20px; } }
  .-ol-label_last {
    margin-bottom: 0; }
  input:focus ~ .-ol-label,
  ._filled ~ .-ol-label {
    top: -9px;
    left: 20px;
    padding: 0 10px; }
    @media only screen and (max-width: 640px) {
      input:focus ~ .-ol-label,
      ._filled ~ .-ol-label {
        left: 10px; } }

.-ol-label-text {
  display: block;
  margin: -5px 0 -7px;
  color: #2F3238;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.04em;
  transition: all .2s; }
  input:focus ~ .-ol-label .-ol-label-text,
  ._filled ~ .-ol-label .-ol-label-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px; }

.-ol-input-link {
  position: absolute;
  top: 26px;
  right: 29px;
  display: block;
  margin: -5px 0 -7px;
  color: #1551ED;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .04em; }
  @media only screen and (max-width: 640px) {
    .-ol-input-link {
      right: 19px; } }

.-ol-input-error {
  position: absolute;
  bottom: calc( 100% + 5px);
  right: 0;
  border-radius: 5px;
  padding: 12px 20px 11px;
  background-color: #FF383B;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: .04em;
  text-align: right; }
  @media only screen and (max-width: 640px) {
    .-ol-input-error {
      padding: 6px 15px;
      font-size: 13px;
      line-height: 23px; } }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #2F3238; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    border-color: #FF383B;
    color: #FF383B; }
  .-ol-input-wrap_error .-ol-label-text {
    color: #FF383B; }
  .-ol-input-wrap_select .-ol-input {
    padding-right: 63px; }
    @media only screen and (max-width: 640px) {
      .-ol-input-wrap_select .-ol-input {
        padding-right: 53px; } }
  .-ol-input-wrap_select::before {
    content: '';
    position: absolute;
    top: 30px;
    right: 25px;
    border: 4px solid transparent;
    border-top-color: #000;
    border-bottom-width: 0; }
    @media only screen and (max-width: 640px) {
      .-ol-input-wrap_select::before {
        right: 20px; } }

.-ol-row-input {
  margin-bottom: 30px; }
  @media only screen and (max-width: 640px) {
    .-ol-row-input {
      margin-bottom: 20px; } }
  .-ol-row-input_last {
    margin-bottom: 0; }

@media only screen and (max-width: 640px) {
  .-ol-row-input-mb {
    margin-bottom: 20px; } }

.-ol-form-body {
  margin-bottom: 40px; }
  @media only screen and (max-width: 640px) {
    .-ol-form-body {
      margin-bottom: 30px; } }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #0F81EA;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #0c67ba;
    color: #0c67ba; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

.i-loudspeaker::after, .b-hero__promotion-inner::after,
.i-menu::after,
.b-header__nav-hamburger-btn::after,
.i-arrow-3::after,
.c-slick-reviews .slick-arrow::after,
.i-plus::after,
.i-star::after,
.b-list-favorites__item::after,
.b-reviews__box-rating-item::after,
.i-cross::after,
.b-box__close::after,
.b-header__nav-popup-cross::after,
.i-logo::after,
.b-footer__logo::after,
.i-support::after,
.i-skype::after,
.b-box-contact__list-item_skype::after,
.i-telegram::after,
.b-box-contact__list-item_telegram::after,
.i-account::after,
.i-attachment::after,
.i-calendar::after,
.i-logout::after,
.i-payments::after,
.i-refferals::after,
.i-stats::after,
.i-arrow::after,
.-ol-btn_next .-ol-btn__text::after,
.i-arrow-2::after,
.b-hero__down::after,
.i-brush::after,
.b-advantages__list-heading_brush::after,
.i-chart::after,
.b-advantages__list-heading_chart::after,
.i-chat::after,
.b-advantages__list-heading_chat::after,
.i-like::after,
.b-advantages__list-heading_like::after,
.i-transparent::after,
.b-advantages__list-heading_transparent::after,
.i-wallet::after,
.b-advantages__list-heading_wallet::after,
.i-envelope::after,
.b-box-contact__list-item_email::after {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  letter-spacing: 0;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

.i-loudspeaker::after, .b-hero__promotion-inner::after {
  content: '\e919'; }


.i-menu::after,
.b-header__nav-hamburger-btn::after {
  content: '\e913'; }


.i-arrow-3::after,
.c-slick-reviews .slick-arrow::after {
  content: '\e917'; }

.i-plus::after {
  content: '\e916'; }


.i-star::after,
.b-list-favorites__item::after,
.b-reviews__box-rating-item::after {
  content: '\e915'; }


.i-cross::after,
.b-box__close::after,
.b-header__nav-popup-cross::after {
  content: '\e918'; }


.i-logo::after,
.b-footer__logo::after {
  content: '\e914'; }

.i-support::after {
  content: '\e900'; }


.i-skype::after,
.b-box-contact__list-item_skype::after {
  content: '\e901'; }


.i-telegram::after,
.b-box-contact__list-item_telegram::after {
  content: '\e902'; }

.i-account::after {
  content: '\e903'; }

.i-attachment::after {
  content: '\e904'; }

.i-calendar::after {
  content: '\e905'; }

.i-logout::after {
  content: '\e906'; }

.i-payments::after {
  content: '\e907'; }

.i-refferals::after {
  content: '\e908'; }

.i-stats::after {
  content: '\e909'; }


.i-arrow::after,
.-ol-btn_next .-ol-btn__text::after {
  content: '\e90a'; }


.i-arrow-2::after,
.b-hero__down::after {
  content: '\e90b'; }


.i-brush::after,
.b-advantages__list-heading_brush::after {
  content: '\e90c'; }


.i-chart::after,
.b-advantages__list-heading_chart::after {
  content: '\e90d'; }


.i-chat::after,
.b-advantages__list-heading_chat::after {
  content: '\e90e'; }


.i-like::after,
.b-advantages__list-heading_like::after {
  content: '\e90f'; }


.i-transparent::after,
.b-advantages__list-heading_transparent::after {
  content: '\e910'; }


.i-wallet::after,
.b-advantages__list-heading_wallet::after {
  content: '\e911'; }


.i-envelope::after,
.b-box-contact__list-item_email::after {
  content: '\e912'; }

/* snippets */
._z_i_1 {
  z-index: 1; }

/* project */
.accordionjs .acc_section .acc_head {
  cursor: pointer; }
  .accordionjs .acc_section .acc_head > *::before, .accordionjs .acc_section .acc_head > *::after {
    content: '';
    position: absolute;
    background-color: #000; }
  .accordionjs .acc_section .acc_head > *::before {
    top: 13px;
    left: -58px;
    width: 16px;
    height: 2px; }
    @media only screen and (max-width: 640px) {
      .accordionjs .acc_section .acc_head > *::before {
        top: 11px;
        left: -46px; } }
  .accordionjs .acc_section .acc_head > *::after {
    top: 6px;
    left: -51px;
    height: 16px;
    width: 2px;
    transform: rotateZ(0deg);
    transition: transform .2s; }
    @media only screen and (max-width: 640px) {
      .accordionjs .acc_section .acc_head > *::after {
        top: 4px;
        left: -39px; } }

.accordionjs .acc_section.acc_active > .acc_head > *::after {
  transform: rotateZ(90deg); }

.c-manipulate-class {
  cursor: pointer; }

.c-slick.slick-slider, .slick-slider.c-slick-reviews {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.c-slick .slick-list, .c-slick-reviews .slick-list,
.c-slick .slick-track,
.c-slick-reviews .slick-track {
  transform: translate3d(0, 0, 0); }

.c-slick.slick-initialized .slick-slide, .slick-initialized.c-slick-reviews .slick-slide {
  display: block; }

.c-slick.slick-loading .slick-slide, .slick-loading.c-slick-reviews .slick-slide,
.c-slick.slick-loading .slick-track,
.slick-loading.c-slick-reviews .slick-track {
  visibility: hidden; }

.c-slick.slick-vertical .slick-slide, .slick-vertical.c-slick-reviews .slick-slide {
  display: block;
  height: auto; }

.c-slick .slick-list, .c-slick-reviews .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .c-slick .slick-list:focus, .c-slick-reviews .slick-list:focus {
    outline: none; }
  .c-slick .slick-list.dragging, .c-slick-reviews .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }


.c-slick .slick-track,
.c-slick-reviews .slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .c-slick .slick-track::before, .c-slick-reviews .slick-track::before, .c-slick .slick-track::after, .c-slick-reviews .slick-track::after {
    content: "";
    display: table; }
  .c-slick .slick-track::after, .c-slick-reviews .slick-track::after {
    clear: both; }

.c-slick .slick-slide, .c-slick-reviews .slick-slide {
  display: none;
  float: left;
  min-height: 1px; }
  .c-slick .slick-slide img, .c-slick-reviews .slick-slide img {
    display: block; }
  .c-slick .slick-slide.slick-loading img, .c-slick-reviews .slick-slide.slick-loading img {
    display: none; }
  .c-slick .slick-slide.dragging img, .c-slick-reviews .slick-slide.dragging img {
    pointer-events: none; }

[dir="rtl"] .c-slick .slick-slide, [dir="rtl"] .c-slick-reviews .slick-slide,
.c-slick [dir="rtl"] .slick-slide,
.c-slick-reviews [dir="rtl"] .slick-slide {
  float: right; }

.c-slick .slick-arrow.slick-hidden, .c-slick-reviews .slick-arrow.slick-hidden {
  display: none; }

.c-slick-reviews {
  display: flex !important; }
  .c-slick-reviews .slick-list,
  .c-slick-reviews .slick-track {
    display: flex;
    flex-grow: 1; }
  .c-slick-reviews .slick-list {
    display: flex;
    flex-direction: column;
    margin: -15px 0 -45px; }
  .c-slick-reviews .slick-slide {
    padding: 15px 0 45px; }
  .c-slick-reviews .slick-arrow {
    position: absolute;
    top: calc( 50% - 40px);
    z-index: 9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fff;
    box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(21, 81, 103, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -5px -5px 10px rgba(121, 141, 148, 0.25);
    font-size: 0; }
    @media only screen and (max-width: 768px) {
      .c-slick-reviews .slick-arrow {
        display: none !important; } }
    .c-slick-reviews .slick-arrow::after {
      position: absolute;
      top: 29px;
      color: #000;
      font-size: 20px;
      transition: all .2s; }
  .c-slick-reviews .slick-prev {
    right: calc( 50% + 285px); }
    @media only screen and (max-width: 1023px) {
      .c-slick-reviews .slick-prev {
        right: calc( 50% + 275px); } }
    .c-slick-reviews .slick-prev::after {
      right: 36px;
      transform: rotateZ(180deg); }
    .c-slick-reviews .slick-prev:hover::after {
      right: 39px; }
  .c-slick-reviews .slick-next {
    left: calc( 50% + 285px); }
    @media only screen and (max-width: 1023px) {
      .c-slick-reviews .slick-next {
        left: calc( 50% + 275px); } }
    .c-slick-reviews .slick-next::after {
      left: 36px; }
    .c-slick-reviews .slick-next:hover::after {
      left: 39px; }

.c-switcher {
  cursor: pointer; }

.l-cell {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed; }
  .l-cell__item {
    display: table-cell;
    vertical-align: top; }
  .l-cell__item_major {
    width: 100%; }
  .l-cell__item_middle {
    vertical-align: middle; }
  @media only screen and (max-width: 640px) {
    .l-cell__item_top_mb {
      vertical-align: top; } }

.l-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.2s filter; }
  ._modal_active .l-content-wrapper {
    filter: blur(3px); }
  .l-content-wrapper__item_major {
    flex-grow: 1; }
  .l-content-wrapper__item_shrink_none {
    flex-shrink: 0; }

.l-flex {
  display: flex;
  flex-wrap: wrap; }
  .l-flex_nowrap {
    flex-wrap: nowrap; }
  .l-flex_valign_center {
    align-items: center; }
  @media only screen and (max-width: 640px) {
    .l-flex_valign_center_mb {
      align-items: center; } }
  .l-flex_valign_center_top_mb {
    align-items: center; }
    @media only screen and (max-width: 640px) {
      .l-flex_valign_center_top_mb {
        align-items: flex-start; } }
  .l-flex_right {
    justify-content: flex-end; }
  .l-flex_center {
    justify-content: center; }
  .l-flex_space_between {
    justify-content: space-between; }
  .l-flex_space_around {
    justify-content: space-around; }
  .l-flex_col {
    flex-direction: column; }
  .l-flex > .l-flex__item_stretch {
    flex-grow: 1; }
  .l-flex > .l-flex__item_major {
    flex-shrink: 0; }
  @media only screen and (max-width: 1230px) {
    .l-flex > .l-flex__item_equal_none_tb {
      flex: 0 1 auto; } }
  .l-flex_equal {
    flex-wrap: nowrap; }
  .l-flex_equal > .l-flex__item {
    flex: 1 1 100%;
    overflow: hidden; }
  .l-flex_equal_with_width > .l-flex__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 0; }

.l-indent {
  margin: -10px -15px; }
  .l-indent_sm {
    margin: -5px -10px; }
  .l-indent__item {
    padding: 10px 15px; }
  .l-indent_sm .l-indent__item {
    padding: 5px 10px; }

.l-inline {
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .l-inline__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .l-inline__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.l-justify {
  font-size: 0 !important;
  letter-spacing: 0 !important;
  text-align: justify;
  text-align-last: justify;
  text-justify: newspaper; }
  .l-justify::after {
    content: '';
    display: inline-block;
    width: 100%;
    visibility: hidden;
    overflow: hidden; }
  .l-justify__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }
  .l-justify__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }

.l-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%; }
  .l-row__item {
    display: table-row; }
  .l-row__item_major {
    height: 100%; }

.b-advantages {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 140px; }
  @media only screen and (max-width: 1230px) {
    .b-advantages {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 640px) {
    .b-advantages {
      margin-bottom: 70px; } }
  .b-advantages::before {
    content: '';
    position: absolute;
    top: -54px;
    left: 0;
    width: 100%;
    height: 630px;
    background-image: url("../../media/img/b-advantages/bg_1.png"), url("../../media/img/b-advantages/bg_2.png"), url("../../media/img/b-advantages/bg_3.png"), url("../../media/img/b-advantages/bg_4.png");
    background-size: 90px 90px, 210px 172px, 145px 50px, 59px 59px;
    background-position: calc( 50% + 575px) 0px, calc( 50% + 932px) 11px, -45px 357px, 93px 571px;
    background-repeat: no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-advantages::before {
        background-image: url("../../media/img/b-advantages/bg_1@2x.png"), url("../../media/img/b-advantages/bg_2@2x.png"), url("../../media/img/b-advantages/bg_3@2x.png"), url("../../media/img/b-advantages/bg_4@2x.png"); } }
    @media only screen and (max-width: 1600px) {
      .b-advantages::before {
        height: 720px;
        background-position: calc( 50% + 575px) 0px, calc( 50% + 932px) 11px, -45px 357px, 58px 641px; } }
    @media only screen and (max-width: 1441px) {
      .b-advantages::before {
        background-image: url("../../media/img/b-advantages/bg_1.png"), url("../../media/img/b-advantages/bg_4.png");
        background-size: 90px 90px, 59px 59px;
        background-position: calc( 50% + 575px) 0px, 28px 661px;
        background-repeat: no-repeat; } }
  @media only screen and (max-width: 1441px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1441px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 1441px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1441px) and (min-device-pixel-ratio: 2), only screen and (max-width: 1441px) and (min-resolution: 192dpi), only screen and (max-width: 1441px) and (min-resolution: 2dppx) {
    .b-advantages::before {
      background-image: url("../../media/img/b-advantages/bg_1@2x.png"), url("../../media/img/b-advantages/bg_4@2x.png"); } }
    @media only screen and (max-width: 1230px) {
      .b-advantages::before {
        background: none; } }
  .b-advantages__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -30px -15px; }
    @media only screen and (max-width: 640px) {
      .b-advantages__list {
        margin: -20px -10px; } }
  .b-advantages__list-item {
    width: 33.3333%;
    min-width: 360px;
    padding: 30px 15px; }
    @media only screen and (max-width: 640px) {
      .b-advantages__list-item {
        width: 100%;
        min-width: 0;
        max-width: 360px;
        padding: 20px 10px; } }
  .b-advantages__list-heading {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    @media only screen and (max-width: 640px) {
      .b-advantages__list-heading {
        margin-bottom: 25px; } }
    .b-advantages__list-heading_wallet::after {
      top: 21px;
      left: 22px;
      font-size: 27px; }
    .b-advantages__list-heading_transparent::after {
      top: 20px;
      left: 20px;
      font-size: 30px; }
    .b-advantages__list-heading_brush::after {
      top: 19px;
      left: 20px;
      font-size: 30px; }
    .b-advantages__list-heading_like::after {
      top: 21px;
      left: 20px;
      font-size: 26px; }
    .b-advantages__list-heading_chart::after {
      top: 21px;
      left: 17px;
      font-size: 28px; }
    .b-advantages__list-heading_chat::after {
      top: 22px;
      left: 19px;
      font-size: 27px; }
    .b-advantages__list-heading_last {
      margin-bottom: -7px; }
    .b-advantages__list-heading::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      margin-right: 30px;
      border-radius: 35px;
      background: #fff;
      box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(21, 81, 103, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -5px -5px 10px rgba(121, 141, 148, 0.25); }
      @media only screen and (max-width: 640px) {
        .b-advantages__list-heading::before {
          margin-right: 20px; } }
    .b-advantages__list-heading::after {
      position: absolute;
      color: #000; }
  .b-advantages__list-title {
    margin: -7px 0;
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: .02em; }
    @media only screen and (max-width: 640px) {
      .b-advantages__list-title {
        margin: -6px 0 -7px;
        font-size: 18px;
        line-height: 26px; } }
  .b-advantages__list-text {
    margin: -9px 0 -10px;
    color: #8C8C8C;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .04em; }

.b-box {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 50px auto; }
  @media only screen and (max-width: 1023px) {
    .b-box {
      margin: 50px auto; } }
  .b-box::before, .b-box::after {
    content: '';
    position: absolute;
    border-radius: 25px; }
  .b-box::before {
    top: 50px;
    left: 50px;
    width: calc( 100% - 100px);
    height: calc( 100% - 50px);
    box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.2); }
  .b-box::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 20px rgba(121, 141, 148, 0.17); }
  .b-box_without_outside_space_horiz {
    margin-left: 0;
    margin-right: 0; }
  .b-box_without_outside_space_vert {
    margin-top: 0;
    margin-bottom: 0; }
  .b-box_without_outside_space {
    margin: 0; }
  .b-box_xl {
    width: 1070px; }
  .b-box_lg {
    width: 780px; }
  .b-box_md {
    width: 570px; }
  .b-box_sm {
    width: 445px; }
  @media only screen and (max-width: 640px) {
    .b-box_sm_mb {
      width: 445px; } }
  @media only screen and (max-width: 1070px) {
    .b-box_xl:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  @media only screen and (max-width: 780px) {
    .b-box_lg:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  .b-box__close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2; }
    @media only screen and (max-width: 640px) {
      .b-box__close {
        top: 20px;
        right: 20px; } }
    .b-box__close::after {
      display: block;
      color: #000;
      font-size: 17px;
      transform: rotate(0deg);
      transition: transform .2s; }
    .b-box__close:hover::after {
      transform: rotate(90deg); }
  .b-box__body {
    position: relative;
    z-index: 1;
    padding: 50px 50px; }
    @media only screen and (max-width: 1023px) {
      .b-box__body {
        padding: 50px 40px; } }
    @media only screen and (max-width: 640px) {
      .b-box__body {
        padding: 50px 20px; } }
    .b-box__body_without_inside_space {
      padding: 0 !important; }
    .b-box__body_style_2::before, .b-box__body_style_2::after, .b-box__body_style_3::before, .b-box__body_style_3::after {
      content: '';
      position: absolute;
      z-index: 1;
      pointer-events: none; }
    .b-box__body_style_2::before, .b-box__body_style_3::before {
      width: 157px;
      height: 130px;
      background: url("../../media/img/b-box/bg_1.png") center/contain no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-box__body_style_2::before, .b-box__body_style_3::before {
          background-image: url("../../media/img/b-box/bg_1@2x.png"); } }
    .b-box__body_style_2::after, .b-box__body_style_3::after {
      width: 112px;
      height: 94px;
      background: url("../../media/img/b-box/bg_2.png") center/contain no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-box__body_style_2::after, .b-box__body_style_3::after {
          background-image: url("../../media/img/b-box/bg_2@2x.png"); } }
    .b-box__body_style_2::before {
      top: -71px;
      right: 92px; }
    .b-box__body_style_2::after {
      bottom: 12px;
      left: -70px; }
    .b-box__body_style_3::before {
      top: 275px;
      right: -73px; }
    .b-box__body_style_3::after {
      bottom: 202px;
      left: -70px; }
  @media only screen and (max-width: 1070px) {
    .b-box_xl .b-box__body {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 780px) {
    .b-box_xl .b-box__body_without_inside_space_mb {
      padding: 0; } }
  @media only screen and (max-width: 780px) {
    .b-box_lg .b-box__body {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 780px) {
    .b-box_lg .b-box__body_without_inside_space_mb {
      padding: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body_without_inside_space_mb {
      padding: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body_without_inside_space_mb {
      padding: 0; } }

.b-box-contact {
  position: relative;
  padding: 60px 50px 45px; }
  @media only screen and (max-width: 1230px) {
    .b-box-contact {
      padding: 50px 40px 45px; } }
  @media only screen and (max-width: 640px) {
    .b-box-contact {
      padding: 0; } }
  .b-box-contact::before, .b-box-contact::after {
    content: '';
    position: absolute;
    border-radius: 25px;
    background: #fff; }
    @media only screen and (max-width: 640px) {
      .b-box-contact::before, .b-box-contact::after {
        display: none; } }
  .b-box-contact::before {
    top: 30px;
    left: 30px;
    width: calc( 100% - 60px);
    height: calc( 100% - 60px);
    box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.1); }
  .b-box-contact::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 20px rgba(121, 141, 148, 0.13); }
  .b-box-contact__inner {
    position: relative;
    z-index: 1; }
  .b-box-contact__form {
    margin-bottom: 40px;
    border-bottom: 1px solid #E2E3EC;
    padding-bottom: 40px; }
  .b-box-contact__list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 50px; }
    .b-box-contact__list-item:last-child {
      margin-bottom: 0; }
    .b-box-contact__list-item::after {
      position: absolute;
      color: #000; }
    .b-box-contact__list-item_email::after {
      top: -2px;
      left: 7px;
      font-size: 14px; }
    .b-box-contact__list-item_skype::after {
      top: -5px;
      left: 6px;
      font-size: 20px; }
    .b-box-contact__list-item_telegram::after {
      top: -4px;
      left: 4px;
      font-size: 18px; }
  .b-box-contact__list-text {
    margin: -7px 0;
    color: #8C8C8C;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: .04em; }

.b-commission {
  position: relative;
  margin-bottom: -30px;
  padding: 240px 0 410px;
  background: url("../../media/img/b-commission/bg.png") center/1920px 100% no-repeat; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .b-commission {
      background-image: url("../../media/img/b-commission/bg@2x.png"); } }
  @media only screen and (min-width: 1920px) {
    .b-commission {
      background-position: 100% 100%; } }
  @media only screen and (max-width: 1230px) {
    .b-commission {
      margin-bottom: -140px;
      padding: 220px 0 380px;
      text-align: center; } }
  @media only screen and (max-width: 640px) {
    .b-commission {
      margin-bottom: -200px;
      padding: 85px 0 360px;
      background-position: 83% center; } }
  .b-commission::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../media/img/b-commission/bg_1.png"), url("../../media/img/b-commission/bg_2.png"), url("../../media/img/b-commission/bg_3.png"), url("../../media/img/b-commission/bg_4.png"), url("../../media/img/b-commission/bg_5.png");
    background-size: 162px 135px, 129px 107px, 55px 55px, 144px 110px, 108px 101px;
    background-position: calc( 50% - 717px) 47px, calc( 50% + 764px) 226px, 178px 733px, calc( 50% - 378px) 847px, calc( 50% + 701px) 989px;
    background-repeat: no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-commission::before {
        background-image: url("../../media/img/b-commission/bg_1@2x.png"), url("../../media/img/b-commission/bg_2@2x.png"), url("../../media/img/b-commission/bg_3@2x.png"), url("../../media/img/b-commission/bg_4@2x.png"), url("../../media/img/b-commission/bg_5@2x.png"); } }
    @media only screen and (max-width: 640px) {
      .b-commission::before {
        display: none; } }
  .b-commission__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .b-commission__list_last {
      margin-bottom: 0; }
  .b-commission__list-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -15px; }
    @media only screen and (max-width: 1023px) {
      .b-commission__list-inner {
        margin: -10px; } }
  .b-commission__list-item {
    width: 25%;
    min-width: 300px;
    padding: 15px; }
    @media only screen and (max-width: 1023px) {
      .b-commission__list-item {
        padding: 10px; } }
    @media only screen and (max-width: 480px) {
      .b-commission__list-item {
        width: 100%;
        min-width: 0;
        max-width: 300px; } }
  .b-commission__list-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
    border-radius: 30px;
    padding: 30px 20px;
    background: #fff;
    box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(21, 81, 103, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 30px rgba(121, 141, 148, 0.25);
    text-align: center; }
    @media only screen and (max-width: 640px) {
      .b-commission__list-box {
        min-height: 160px;
        border-radius: 25px; } }
    .b-commission__list-box_special {
      background: #FFE14D;
      box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(0, 0, 0, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 30px rgba(102, 83, 56, 0.2); }
  .b-commission__list-title {
    margin: -6px 0 -7px;
    color: #000;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: .08em; }
  .b-commission__list-number {
    margin: -5px 0 10px;
    color: #000;
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: .04em; }
    .b-commission__list-number_sm {
      font-size: 24px; }
    .b-commission__list-number_last {
      margin-bottom: -5px; }
  .b-commission__text {
    margin: -7px 0 -8px;
    color: #000;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: .04em; }

.b-contacts {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  @media only screen and (max-width: 1023px) {
    .b-contacts {
      flex-direction: column; } }
  .b-contacts__item {
    width: 50%;
    max-width: 570px;
    padding: 0 15px; }
    @media only screen and (max-width: 1023px) {
      .b-contacts__item {
        width: 100%;
        margin: 0 auto 70px; } }
    @media only screen and (max-width: 1023px) {
      .b-contacts__item:last-child {
        margin-bottom: 0; } }
  .b-contacts__section {
    margin-bottom: 75px;
    padding-left: 20px; }
    @media only screen and (max-width: 1023px) {
      .b-contacts__section {
        margin-bottom: 70px;
        padding-left: 0; } }
    .b-contacts__section_first {
      padding-top: 60px; }
      @media only screen and (max-width: 1023px) {
        .b-contacts__section_first {
          padding-top: 0; } }
    .b-contacts__section:last-child {
      margin-bottom: 0; }

.b-content {
  display: flex;
  flex-direction: column;
  align-items: start; }
  .b-content_sm {
    max-width: 770px;
    margin: 0 auto; }
  .b-content h2 {
    margin: 51px 0 27px;
    color: #000;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: .06em; }
    .b-content h2:first-child {
      margin-top: -9px; }
    .b-content h2:last-child {
      margin-bottom: -8px; }
    .b-content h2 + h3 {
      margin-top: -4px; }
    .b-content h2 + img,
    .b-content h2 + video {
      margin-top: 5px; }
    .b-content h2 + ul,
    .b-content h2 + ol {
      margin-top: 5px; }
  .b-content h3 {
    margin: 51px 0 28px;
    color: #000;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: .04em; }
    .b-content h3:first-child {
      margin-top: -9px; }
    .b-content h3:last-child {
      margin-bottom: -7px; }
    .b-content h3 + h2 {
      margin-top: 16px; }
    .b-content h3 + img,
    .b-content h3 + video {
      margin-top: 5px; }
    .b-content h3 + ul,
    .b-content h3 + ol {
      margin-top: 5px; }
  .b-content p {
    margin: -10px 0 39px;
    color: #2F3238;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .04em;
    text-align: justify; }
    @media only screen and (max-width: 640px) {
      .b-content p {
        margin: -10px 0 29px; } }
    .b-content p:last-child {
      margin-bottom: -11px; }
    .b-content p + h2 {
      margin-top: 1px; }
      @media only screen and (max-width: 640px) {
        .b-content p + h2 {
          margin-top: 11px; } }
    .b-content p + h3 {
      margin-top: 1px; }
      @media only screen and (max-width: 640px) {
        .b-content p + h3 {
          margin-top: 11px; } }
  .b-content a {
    display: inline;
    border-bottom: 1px solid transparent;
    color: #0F81EA;
    line-height: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    transition: all .2s; }
    .b-content a:hover {
      border-bottom-color: #0c67ba;
      color: #0c67ba; }
  .b-content b {
    line-height: 0;
    color: #000;
    font-weight: 700; }
  .b-content i {
    font-style: italic; }
  .b-content ul,
  .b-content ol {
    margin-bottom: 40px; }
    .b-content ul:first-child,
    .b-content ol:first-child {
      margin-top: 0; }
    .b-content ul:last-child,
    .b-content ol:last-child {
      margin-bottom: 0; }
    .b-content ul + h2,
    .b-content ol + h2 {
      margin-top: 11px; }
    .b-content ul + h3,
    .b-content ol + h3 {
      margin-top: 11px; }
  .b-content ol {
    counter-reset: content-list; }
  .b-content li {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-left: 30px; }
    .b-content li:last-child {
      margin-bottom: 0; }
  .b-content ul li::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 2px solid #FF383B; }
  .b-content ul li:nth-child(3n - 1)::before {
    border-color: #FFC224; }
  .b-content ul li:nth-child(3n)::before {
    border-color: #58c067; }
  .b-content ol li::before {
    counter-increment: content-list;
    content: counter(content-list) ".";
    position: absolute;
    top: -1px;
    left: 0;
    margin: -10px 0 -11px;
    color: #555555;
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: .04em; }
  .b-content img,
  .b-content video,
  .b-content iframe,
  .b-content .b-content__block,
  .b-content button {
    margin-bottom: 40px; }
    .b-content img:last-child,
    .b-content video:last-child,
    .b-content iframe:last-child,
    .b-content .b-content__block:last-child,
    .b-content button:last-child {
      margin-bottom: 0; }
    .b-content img + h2,
    .b-content video + h2,
    .b-content iframe + h2,
    .b-content .b-content__block + h2,
    .b-content button + h2 {
      margin-top: 11px; }
    .b-content img + h3,
    .b-content video + h3,
    .b-content iframe + h3,
    .b-content .b-content__block + h3,
    .b-content button + h3 {
      margin-top: 11px; }
  .b-content video,
  .b-content iframe,
  .b-content .b-content__block {
    display: block;
    max-width: 100%; }
  .b-content table {
    width: 100%;
    text-align: left; }
  .b-content tr {
    border-bottom-width: 1px color #ebebeb; }
  .b-content td,
  .b-content th {
    padding: 10px 10px;
    vertical-align: middle; }
    .b-content td:first-child,
    .b-content th:first-child {
      padding-left: 0; }
    .b-content td:last-child,
    .b-content th:last-child {
      padding-right: 0; }
  .b-content thead th {
    font-size: 1.1em;
    font-weight: 600; }
  .b-content form {
    width: 580px;
    max-width: 100%; }

.b-faq {
  max-width: 830px;
  margin: 0 auto; }
  .b-faq__box {
    position: relative;
    min-height: 90px;
    margin-bottom: 14px; }
    @media only screen and (max-width: 640px) {
      .b-faq__box {
        min-height: 78px;
        margin-bottom: 16px; } }
    .b-faq__box:last-child {
      margin-bottom: 0; }
    .b-faq__box::before, .b-faq__box::after {
      content: '';
      position: absolute;
      border-radius: 25px; }
      @media only screen and (max-width: 1023px) {
        .b-faq__box::before, .b-faq__box::after {
          border-radius: 20px; } }
      @media only screen and (max-width: 640px) {
        .b-faq__box::before, .b-faq__box::after {
          border-radius: 17px; } }
    .b-faq__box::before {
      top: 15px;
      left: 15px;
      width: calc( 100% - 30px);
      height: calc( 100% - 15px);
      box-shadow: 0px 15px 35px rgba(21, 81, 103, 0.15); }
      @media only screen and (max-width: 640px) {
        .b-faq__box::before {
          box-shadow: 0px 10px 25px rgba(21, 81, 103, 0.15); } }
    .b-faq__box::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 20px rgba(121, 141, 148, 0.17); }
      @media only screen and (max-width: 640px) {
        .b-faq__box::after {
          box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -10px -10px 15px rgba(121, 141, 148, 0.17); } }
  .b-faq__box-top {
    padding: 38px 30px 38px 100px; }
    @media only screen and (max-width: 640px) {
      .b-faq__box-top {
        padding: 33px 25px 33px 76px; } }
    .b-faq__box-top::before {
      content: '';
      position: absolute;
      left: 23px;
      top: 18px;
      z-index: 1;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #fff;
      box-shadow: inset 7px 7px 10px rgba(121, 141, 148, 0.12); }
      @media only screen and (max-width: 640px) {
        .b-faq__box-top::before {
          left: 15px;
          top: 16px;
          width: 46px;
          height: 46px; } }
  .b-faq__heading {
    position: relative;
    z-index: 1;
    margin: -7px 0 -8px;
    color: #000;
    font-size: 19px;
    line-height: 29px;
    font-weight: 500;
    letter-spacing: .04em; }
    @media only screen and (max-width: 640px) {
      .b-faq__heading {
        margin: -6px 0 -7px;
        font-size: 17px;
        line-height: 25px; } }
  .b-faq__box-content {
    display: flex;
    flex-direction: column;
    margin-top: -8px;
    padding: 0 30px 38px 100px; }
    @media only screen and (max-width: 640px) {
      .b-faq__box-content {
        padding: 0 25px 33px 76px; } }
  .b-faq__text {
    position: relative;
    z-index: 1;
    margin: -10px 0 -11px;
    color: #000;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: .04em; }
    @media only screen and (max-width: 640px) {
      .b-faq__text {
        margin: -9px 0 -10px;
        font-size: 15px;
        line-height: 30px; } }

.b-footer {
  position: relative;
  overflow: hidden;
  padding: 100px 0 60px;
  background-image: url("../../media/img/b-footer/bg.png");
  background-size: 1920px 110px;
  background-position: center top;
  background-repeat: no-repeat; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .b-footer {
      background-image: url("../../media/img/b-footer/bg@2x.png"); } }
  @media only screen and (min-width: 1920px) {
    .b-footer {
      background-size: 100% 110px; } }
  @media only screen and (max-width: 1023px) {
    .b-footer {
      padding-bottom: 40px; } }
  @media only screen and (max-width: 640px) {
    .b-footer {
      padding-top: 85px;
      text-align: center; } }
  .b-footer::before {
    content: '';
    position: absolute;
    top: 110px;
    left: 0;
    width: 100%;
    height: calc( 100% - 110px);
    background-color: #000; }
  .b-footer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../media/img/b-footer/bg_1.png"), url("../../media/img/b-footer/bg_2.png"), url("../../media/img/b-footer/bg_3.png"), url("../../media/img/b-footer/bg_4.png"), url("../../media/img/b-footer/bg_5.png");
    background-size: 100px 100px, 81px 86px, 88px 89px, 60px 60px, 115px 115px;
    background-position: calc( 50% - 212px) 28px, calc( 55% - 735px) 64px, calc( 50% + 637px) 92px, calc( 50% - 960px) 189px, calc( 50% + 117px) 212px;
    background-repeat: no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-footer::after {
        background-image: url("../../media/img/b-footer/bg_1@2x.png"), url("../../media/img/b-footer/bg_2@2x.png"), url("../../media/img/b-footer/bg_3@2x.png"), url("../../media/img/b-footer/bg_4@2x.png"), url("../../media/img/b-footer/bg_5@2x.png"); } }
    @media only screen and (max-width: 640px) {
      .b-footer::after {
        display: none; } }
  .b-footer__inner {
    position: relative;
    z-index: 1; }
  .b-footer__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: -15px -30px 25px -30px; }
    @media only screen and (max-width: 1023px) {
      .b-footer__row {
        margin: -15px -25px 25px -25px; } }
    @media only screen and (max-width: 640px) {
      .b-footer__row {
        justify-content: center; } }
    .b-footer__row_text {
      align-items: baseline; }
    .b-footer__row:last-child {
      margin-bottom: -15px; }
  .b-footer__item {
    margin: 15px 30px; }
    @media only screen and (max-width: 1023px) {
      .b-footer__item {
        margin: 15px 25px; } }
  .b-footer__logo::after {
    color: rgba(255, 255, 255, 0.3);
    font-size: 38px; }
  .b-footer__nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -12px -30px; }
    @media only screen and (max-width: 1023px) {
      .b-footer__nav {
        margin: -12px -25px; } }
  .b-footer__nav-item {
    margin: 12px 30px; }
    @media only screen and (max-width: 1023px) {
      .b-footer__nav-item {
        margin: 12px 25px; } }
  .b-footer__nav-link {
    display: block;
    margin: -6px 0 -7px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: .06em;
    transition: color .2s; }
    .b-footer__nav-link_active, .b-footer__nav-link:hover {
      color: #fff; }
    .b-footer__nav-link_active {
      pointer-events: none; }
  .b-footer__btns {
    display: flex;
    flex-direction: column; }
  .b-footer__btns-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -10px -15px; }
  .b-footer__btns-item {
    margin: 10px 15px; }
  .b-footer__btn {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 6px; }
    .b-footer__btn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.35), -3px -3px 15px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(0, 0, 0, 0.15);
      transition: box-shadow .2s; }
    .b-footer__btn:hover::before {
      box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25), -3px -3px 15px rgba(255, 255, 255, 0.1), 5px 5px 15px rgba(0, 0, 0, 0.05); }
    .b-footer__btn:active::before {
      box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.15), -3px -3px 15px rgba(255, 255, 255, 0), 5px 5px 15px rgba(0, 0, 0, 0); }
  .b-footer__btn-img {
    height: 60px; }
  .b-footer__text {
    margin: -6px 0 -7px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
    letter-spacing: .06em; }

.b-get-started {
  margin: -60px 0 -70px;
  padding: 280px 0 140px;
  background-image: url("../../media/img/b-get-started/bg_1.png"), url("../../media/img/b-get-started/bg_2.png"), url("../../media/img/b-get-started/bg.png");
  background-size: 193px 158px, 162px 134px, 1920px calc( 100% -  60px);
  background-position: calc( 50% + 713px) 1px, calc( 50% - 697px) 247px, 50% 60px;
  background-repeat: no-repeat;
  text-align: center; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .b-get-started {
      background-image: url("../../media/img/b-get-started/bg_1@2x.png"), url("../../media/img/b-get-started/bg_2@2x.png"), url("../../media/img/b-get-started/bg2x.png"); } }
  @media only screen and (min-width: 1920px) {
    .b-get-started {
      background-size: 193px 158px, 162px 134px, 100% calc( 100% -  60px);
      background-position: calc( 50% + 713px) 1px, calc( 50% - 697px) 247px, 50% 60px; } }
  @media only screen and (max-width: 1023px) {
    .b-get-started {
      margin: 0 0 -70px;
      padding: 120px 0 130px;
      background-image: url("../../media/img/b-get-started/bg.png");
      background-size: 1920px 100%;
      background-position: 50% 0; } }
  @media only screen and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1023px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 1023px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1023px) and (min-device-pixel-ratio: 2), only screen and (max-width: 1023px) and (min-resolution: 192dpi), only screen and (max-width: 1023px) and (min-resolution: 2dppx) {
    .b-get-started {
      background-image: url("../../media/img/b-get-started/bg@2x.png"); } }
  @media only screen and (max-width: 640px) {
    .b-get-started {
      margin-top: -30px;
      padding: 115px 0; } }
  .b-get-started__heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px; }
    @media only screen and (max-width: 1023px) {
      .b-get-started__heading {
        margin-bottom: 40px; } }
    @media only screen and (max-width: 640px) {
      .b-get-started__heading {
        margin-bottom: 30px; } }
    .b-get-started__heading_last {
      margin-bottom: 0; }
  .b-get-started__title {
    margin: -11px 0 21px;
    color: #000;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    letter-spacing: .02em;
    text-shadow: -1px 1px 1px rgba(255, 255, 255, 0.5); }
    @media only screen and (max-width: 1023px) {
      .b-get-started__title {
        margin: -11px 0 17px;
        font-size: 42px;
        line-height: 50px; } }
    @media only screen and (max-width: 640px) {
      .b-get-started__title {
        margin: -10px 0 16px;
        font-size: 36px;
        line-height: 44px; } }
    .b-get-started__title_last {
      margin-bottom: -11px; }
      @media only screen and (max-width: 1023px) {
        .b-get-started__title_last {
          margin-bottom: -10px; } }
      @media only screen and (max-width: 640px) {
        .b-get-started__title_last {
          margin-bottom: -9px; } }
  .b-get-started__subtitle {
    margin: -11px 0;
    color: #000;
    font-size: 34px;
    line-height: 46px;
    font-weight: 700;
    letter-spacing: .02em;
    text-shadow: -1px 1px 1px rgba(255, 255, 255, 0.5); }
    @media only screen and (max-width: 1023px) {
      .b-get-started__subtitle {
        margin: -9px 0 -11px;
        font-size: 30px;
        line-height: 42px; } }
    @media only screen and (max-width: 640px) {
      .b-get-started__subtitle {
        margin: -8px 0 -7px;
        font-size: 26px;
        line-height: 34px; } }

.b-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 15px 50px rgba(21, 81, 103, 0);
  transition: all .2s; }
  @media only screen and (max-width: 1230px) {
    .b-header {
      min-height: 63px;
      padding: 20px; } }
  .b-header_active {
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0px 15px 50px rgba(21, 81, 103, 0.15); }
  .b-header__logo {
    display: block;
    position: absolute !important;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 1230px) {
      .b-header__logo {
        position: static !important;
        transform: none; } }
  .b-header__logo-img {
    height: 25px; }
  .b-header__nav {
    display: flex;
    flex-direction: column;
    padding-left: 15px; }
  .b-header__nav-inner {
    display: flex; }
  .b-header__nav-item {
    margin: 0 25px; }
  .b-header__nav-text {
    margin: -5px 0 -7px;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: .06em; }
  .b-header__language {
    display: flex;
    align-items: center;
    height: 41px;
    margin-bottom: 2px;
    border-radius: 7px;
    padding: 10px 15px 10px 10px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.35), 5px 5px 20px rgba(0, 0, 0, 0.1); }
  .b-header__language-flag {
    position: relative;
    overflow: hidden;
    margin-right: 12px;
    border-radius: 3px;
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.35), 2px 2px 5px rgba(0, 0, 0, 0.1); }
    .b-header__language-flag::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.35), 2px 2px 5px rgba(0, 0, 0, 0.1); }
  .b-header__language-flag-img {
    height: 21px; }
  .b-header__language-text {
    margin: -5px 0 -7px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .06em; }
  .b-header__options {
    display: flex;
    flex-direction: column; }
  .b-header__options-inner {
    display: flex;
    align-items: center;
    margin: 0 -20px; }
  .b-header__options-item {
    margin: 0 20px; }
    .b-header__options-item_valign_b {
      padding-bottom: 2px; }
  .b-header__nav-hamburger {
    display: none; }
    @media only screen and (max-width: 1230px) {
      .b-header__nav-hamburger {
        display: block; } }
  @media only screen and (max-width: 1230px) {
    .b-header__nav-hamburger-btn {
      top: 23px;
      right: 23px; } }
  .b-header__nav-hamburger-btn::after {
    display: block;
    color: #000;
    font-size: 17px; }
  .b-header__nav-popup {
    position: fixed;
    top: 0;
    right: -280px;
    z-index: 99;
    overflow: auto;
    width: 280px;
    height: 100vh;
    padding: 60px 30px 30px;
    background: #000;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    transition: right .2s;
    pointer-events: none; }
  .b-header__nav-hamburger_active .b-header__nav-popup {
    right: 0;
    pointer-events: auto; }
  .b-header__nav-popup-cross {
    position: absolute;
    top: 24px;
    right: 24px; }
    .b-header__nav-popup-cross::after {
      display: block;
      color: #fff;
      font-size: 15px;
      transform: rotate(0deg);
      transition: transform .2s; }
    .b-header__nav-popup-cross:hover::after {
      transform: rotate(90deg); }
  .b-header__nav-popup-list {
    position: relative;
    z-index: 1;
    margin-bottom: 40px; }
    .b-header__nav-popup-list_last {
      margin-bottom: 0; }
  .b-header__nav-popup-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 20px; }
    .b-header__nav-popup-list-item:last-child {
      margin-bottom: 0; }
  .b-header__nav-popup-link {
    display: block;
    margin: -5px 0 -7px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: .04em;
    transition: color .2s; }
    .b-header__nav-popup-link:hover, .b-header__nav-popup-link_active {
      color: #FFC224; }
  .b-header__nav-popup-item {
    margin-bottom: 20px; }
    .b-header__nav-popup-item_last {
      margin-bottom: 0; }

.b-heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px; }
  @media only screen and (max-width: 1023px) {
    .b-heading {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 640px) {
    .b-heading {
      margin-bottom: 45px; } }
  .b-heading_m_b_lg {
    margin-bottom: 90px; }
    @media only screen and (max-width: 1023px) {
      .b-heading_m_b_lg {
        margin-bottom: 70px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_lg {
        margin-bottom: 50px; } }
  .b-heading_m_b_sm {
    margin-bottom: 50px; }
    @media only screen and (max-width: 1023px) {
      .b-heading_m_b_sm {
        margin-bottom: 40px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_sm {
        margin-bottom: 35px; } }
  .b-heading__title {
    margin: -9px 0 41px;
    color: #000;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    letter-spacing: .04em;
    text-align: center;
    text-shadow: -1px 1px 1px rgba(255, 255, 255, 0.5); }
    @media only screen and (max-width: 1023px) {
      .b-heading__title {
        margin: -9px 0 35px;
        font-size: 50px;
        line-height: 54px; } }
    @media only screen and (max-width: 640px) {
      .b-heading__title {
        margin: -9px 0 26px;
        font-size: 40px;
        line-height: 46px; } }
    .b-heading__title_last {
      margin-bottom: -9px; }
      @media only screen and (max-width: 1023px) {
        .b-heading__title_last {
          margin-bottom: -10px; } }
      @media only screen and (max-width: 640px) {
        .b-heading__title_last {
          margin-bottom: -9px; } }
  .b-heading__text {
    margin: -12px 0 -13px;
    color: #2F3238;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: .04em;
    text-align: center; }
    @media only screen and (max-width: 640px) {
      .b-heading__text {
        margin: -10px 0 -11px;
        font-size: 14px;
        line-height: 32px; } }

.b-heading-2 {
  margin: -8px 0 36px;
  color: #000;
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: .06em;
  text-transform: uppercase; }
  @media only screen and (max-width: 640px) {
    .b-heading-2 {
      margin: -7px 0 28px;
      font-size: 20px;
      line-height: 28px; } }
  .b-heading-2_last {
    margin-bottom: -9px; }
    @media only screen and (max-width: 640px) {
      .b-heading-2_last {
        margin-bottom: -7px; } }

.b-heading-3 {
  margin: -7px 0 17px;
  color: #000;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: .04em;
  text-transform: uppercase; }
  .b-heading-3_m_b_lg {
    margin-bottom: 27px; }
  .b-heading-3_last {
    margin-bottom: -8px; }

.b-heading-4 {
  margin: -4px 0 46px;
  color: #000;
  font-size: 28px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: .04em; }
  @media only screen and (max-width: 640px) {
    .b-heading-4 {
      margin: -4px 0 31px; } }
  .b-heading-4_last {
    margin-bottom: -4px; }

.b-hero-img {
  max-width: 970px;
  margin: 0 auto 60px; }
  @media only screen and (max-width: 1023px) {
    .b-hero-img {
      margin-bottom: 45px; } }
  @media only screen and (max-width: 640px) {
    .b-hero-img {
      margin-bottom: 40px; } }
  .b-hero-img_last {
    margin-bottom: 0; }

.b-hero {
  position: relative;
  min-height: 1120px;
  margin-bottom: -29px;
  padding: 215px 0 180px;
  background-image: url("../../media/img/b-hero/bg_4.png"), url("../../media/img/b-hero/bg_1.png"), url("../../media/img/b-hero/bg_2.png"), url("../../media/img/b-hero/bg_3.png"), url("../../media/img/b-hero/bg.png");
  background-size: 868px 824px, 151px 126px, 166px 137px, 110px 105px, 1920px 1120px;
  background-position: calc( 50% + 498px) 146px, calc( 50% + 395px) 177px, 25px 625px, 234px 929px, 50%;
  background-repeat: no-repeat; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .b-hero {
      background-image: url("../../media/img/b-hero/bg_4@2x.png"), url("../../media/img/b-hero/bg_1@2x.png"), url("../../media/img/b-hero/bg_2@2x.png"), url("../../media/img/b-hero/bg_3@2x.png"), url("../../media/img/b-hero/bg2x.png"); } }
  @media only screen and (min-width: 1920px) {
    .b-hero {
      background-size: 868px 824px, 151px 126px, 166px 137px, 110px 105px, 100% 1120px; } }
  @media only screen and (max-width: 1441px) {
    .b-hero {
      background-position: calc( 50% + 595px) 146px, calc( 50% + 395px) 177px, 25px 625px, 234px 929px, 50%; } }
  @media only screen and (max-width: 1230px) {
    .b-hero {
      min-height: 0;
      padding: 160px 0 320px;
      margin-bottom: -90px;
      background-image: url("../../media/img/b-hero/bg_1.png"), url("../../media/img/b-hero/bg_2.png"), url("../../media/img/b-hero/bg.png");
      background-size: 151px 126px, 166px 137px, 1920px 1120px;
      background-position: calc( 50% + 430px) 100px, 25px 625px, 50% 100%;
      background-repeat: no-repeat;
      text-align: center; } }
  @media only screen and (max-width: 1230px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 1230px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 1230px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 1230px) and (min-device-pixel-ratio: 2), only screen and (max-width: 1230px) and (min-resolution: 192dpi), only screen and (max-width: 1230px) and (min-resolution: 2dppx) {
    .b-hero {
      background-image: url("../../media/img/b-hero/bg_1@2x.png"), url("../../media/img/b-hero/bg_2@2x.png"), url("../../media/img/b-hero/bg@2x.png"); } }
  @media only screen and (max-width: 1023px) {
    .b-hero {
      padding: 110px 0 320px; } }
  @media only screen and (max-width: 640px) {
    .b-hero {
      padding: 90px 0 300px;
      margin-bottom: -120px;
      background-position: calc( 50% + 220px) 60px, 5px 79%, 50% 100%; } }
  .b-hero__content {
    max-width: 720px;
    margin-left: -50px; }
    @media only screen and (max-width: 1600px) {
      .b-hero__content {
        margin-left: 0; } }
    @media only screen and (max-width: 1441px) {
      .b-hero__content {
        margin-left: 100px; } }
    @media only screen and (max-width: 1230px) {
      .b-hero__content {
        margin: 0 auto; } }
  .b-hero__img {
    max-height: 137px;
    margin-left: -2px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 1230px) {
      .b-hero__img {
        margin: 0 auto 17px; } }
    @media only screen and (max-width: 1023px) {
      .b-hero__img {
        max-height: 90px; } }
  .b-hero__promotion {
    position: relative;
    margin-bottom: 40px;
    padding: 35px 0; }
    @media only screen and (max-width: 1023px) {
      .b-hero__promotion {
        margin-bottom: 35px;
        border-radius: 40px;
        padding: 35px 30px;
        background: linear-gradient(270deg, #FFE14D 0%, #FFEE99 100%), #FCE972;
        box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 15px 25px rgba(0, 0, 0, 0.12), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 30px rgba(180, 152, 50, 0.3); } }
    @media only screen and (max-width: 640px) {
      .b-hero__promotion {
        margin-bottom: 30px;
        border-radius: 30px;
        padding: 30px 15px; } }
    .b-hero__promotion_last {
      margin-bottom: 0; }
    .b-hero__promotion::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -135px;
      width: 917px;
      height: 224px;
      background: url("../../media/img/b-hero/bg_box.png") center/contain no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-hero__promotion::before {
          background-image: url("../../media/img/b-hero/bg_box@2x.png"); } }
      @media only screen and (max-width: 1023px) {
        .b-hero__promotion::before {
          display: none; } }
  .b-hero__promotion-inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column; }
    .b-hero__promotion-inner::before {
      content: '';
      position: absolute;
      top: -18px;
      left: -100px;
      display: block;
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      margin-right: 30px;
      border-radius: 35px;
      background: #fff;
      box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(21, 81, 103, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -5px -5px 10px rgba(121, 141, 148, 0.25); }
      @media only screen and (max-width: 1023px) {
        .b-hero__promotion-inner::before {
          display: none; } }
    .b-hero__promotion-inner::after {
      position: absolute;
      top: 2px;
      left: -79px;
      font-size: 30px;
      color: #000;
      transform: rotateZ(-15deg); }
      @media only screen and (max-width: 1023px) {
        .b-hero__promotion-inner::after {
          display: none; } }
  .b-hero__promotion-text {
    margin: -13px 0;
    color: #2F3238;
    font-size: 20px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1023px) {
      .b-hero__promotion-text {
        margin: -11px 0 -12px;
        font-size: 18px;
        line-height: 36px; } }
    @media only screen and (max-width: 640px) {
      .b-hero__promotion-text {
        margin: -9px 0 -11px;
        font-size: 16px;
        line-height: 32px; } }
  .b-hero__text {
    margin: -11px 0 28px;
    color: #2F3238;
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1230px) {
      .b-hero__text {
        margin-bottom: 27px; } }
    @media only screen and (max-width: 640px) {
      .b-hero__text {
        margin: -9px 0 21px;
        font-size: 14px;
        line-height: 28px; } }
    .b-hero__text_last {
      margin-bottom: -12px; }
      @media only screen and (max-width: 640px) {
        .b-hero__text_last {
          margin-bottom: -9px; } }
  .b-hero__down {
    position: absolute;
    left: calc( 50% - 40px);
    top: 860px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fff;
    box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1), 7px 7px 15px rgba(21, 81, 103, 0.15), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -5px -5px 10px rgba(121, 141, 148, 0.25); }
    @media only screen and (max-width: 1230px) {
      .b-hero__down {
        display: none; } }
    .b-hero__down::after {
      position: absolute;
      top: 38px;
      left: 30px;
      color: #000;
      font-size: 12px; }

.b-list-blocks {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  .b-list-blocks_last, .b-list-blocks_last_if_last:last-child {
    margin-bottom: 0; }
  .b-list-blocks__inner {
    display: flex;
    margin: -15px; }
    @media only screen and (max-width: 640px) {
      .b-list-blocks__inner {
        margin: -10px; } }
  .b-list-blocks_wrap .b-list-blocks__inner {
    flex-wrap: wrap; }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_col_mb .b-list-blocks__inner {
      flex-direction: column; } }
  .b-list-blocks_side_by_side .b-list-blocks__inner {
    justify-content: space-between; }
  .b-list-blocks_right .b-list-blocks__inner {
    justify-content: flex-end; }
  .b-list-blocks_equal .b-list-blocks__inner {
    flex-wrap: nowrap; }
  .b-list-blocks_valign .b-list-blocks__inner {
    align-items: center; }
  .b-list-blocks__item {
    margin: 15px; }
    @media only screen and (max-width: 640px) {
      .b-list-blocks__item {
        margin: 10px; } }
  .b-list-blocks_equal .b-list-blocks__item {
    flex: 1 1 100%;
    overflow: hidden; }

.b-list-favorites {
  max-width: 450px; }
  .b-list-favorites__item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-left: 40px; }
    .b-list-favorites__item:last-child {
      margin-bottom: 0; }
    .b-list-favorites__item::after {
      position: absolute;
      top: -5px;
      left: 0;
      color: #FFC224;
      font-size: 20px; }
  .b-list-favorites__text {
    margin: -11px 0;
    color: #8C8C8C;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: .04em; }

.b-logos-bw {
  display: flex;
  flex-direction: column; }
  .b-logos-bw__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px; }
  .b-logos-bw__item {
    margin: 10px 20px; }
  .b-logos-bw__img {
    filter: grayscale(100%); }
    .b-logos-bw__img_style_2 {
      margin-top: 5px; }

.b-modal {
  position: fixed;
  top: -100%;
  left: -100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 0.2s;
  transition-property: all, opacity;
  transition-timing-function: step-end, ease-out;
  -webkit-overflow-scrolling: touch; }
  .b-modal_active {
    visibility: visible;
    top: 0;
    left: 0;
    opacity: 1;
    transition-timing-function: step-start, ease-out; }
  .b-modal__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.6);
    cursor: pointer; }
  @media only screen and (max-width: 1070px) {
    .b-modal_xl .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 1070px) {
    .b-modal_xl .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  @media only screen and (max-width: 780px) {
    .b-modal_lg .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 780px) {
    .b-modal_lg .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  .b-modal__close {
    cursor: pointer; }
  .b-modal__inner {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .b-modal__inner-item {
    display: table-cell;
    vertical-align: middle; }
    .b-modal__inner-item_top {
      vertical-align: top; }
  @media only screen and (max-width: 1070px) {
    .b-modal_xl .b-modal__inner-item {
      vertical-align: top !important; } }
  @media only screen and (max-width: 780px) {
    .b-modal_lg .b-modal__inner-item {
      vertical-align: top !important; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__inner-item {
      vertical-align: top !important; } }
  @media only screen and (max-width: 640px) {
    .b-modal_sm .b-modal__inner-item {
      vertical-align: top !important; } }
  .b-modal__box-wrapper {
    margin: 0 auto;
    padding: 40px; }
    .b-modal__box-wrapper_without_inside_space_horiz {
      padding-left: 0;
      padding-right: 0; }
    .b-modal__box-wrapper_without_inside_space {
      padding: 0; }
  @media only screen and (max-width: 1230px) {
    .b-modal_xl .b-modal__box-wrapper {
      padding: 40px 20px; } }
  @media only screen and (max-width: 1070px) {
    .b-modal_xl .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 1230px) {
    .b-modal_lg .b-modal__box-wrapper {
      padding: 40px 20px; } }
  @media only screen and (max-width: 780px) {
    .b-modal_lg .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 1230px) {
    .b-modal_md .b-modal__box-wrapper {
      padding: 40px 20px; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 640px) {
    .b-modal_sm .b-modal__box-wrapper {
      padding: 40px 0; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 1070px) {
    .b-modal_xl.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  @media only screen and (max-width: 780px) {
    .b-modal_lg.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  .b-modal__box {
    position: relative;
    z-index: 1; }

.b-reviews {
  position: relative;
  padding-bottom: 190px;
  margin-bottom: -70px; }
  @media only screen and (max-width: 1023px) {
    .b-reviews {
      padding-bottom: 170px; } }
  @media only screen and (max-width: 640px) {
    .b-reviews {
      padding-bottom: 115px;
      text-align: center; } }
  .b-reviews_last {
    margin-bottom: 0; }
  .b-reviews::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 800px;
    background: linear-gradient(180deg, rgba(223, 226, 234, 0) 0%, rgba(223, 226, 234, 0.8) 100%); }
  .b-reviews::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../media/img/b-reviews/bg_1.png"), url("../../media/img/b-reviews/bg_2.png"), url("../../media/img/b-reviews/bg_3.png");
    background-size: 87px 100px, 90px 90px, 169px 74px;
    background-position: calc( 50% - 898px) 111px, calc( 50% + 575px) 428px, calc( 50% - 468px) 475px;
    background-repeat: no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-reviews::after {
        background-image: url("../../media/img/b-reviews/bg_1@2x.png"), url("../../media/img/b-reviews/bg_2@2x.png"), url("../../media/img/b-reviews/bg_3@2x.png"); } }
    @media only screen and (max-width: 640px) {
      .b-reviews::after {
        display: none; } }
  .b-reviews > * {
    position: relative;
    z-index: 1; }
  .b-reviews__list {
    display: flex;
    justify-content: center; }
  .b-reviews__list-item {
    margin: 0 25px; }
    @media only screen and (max-width: 1023px) {
      .b-reviews__list-item {
        margin: 0 15px; } }
  .b-reviews__box {
    opacity: .6;
    width: 520px;
    max-width: calc( 100vw - 60px);
    min-height: 355px;
    border-radius: 40px;
    padding: 40px 30px;
    background: #fff;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1), inset 1px 1px 0px rgba(255, 255, 255, 0.5), inset -15px -15px 30px rgba(121, 141, 148, 0.25);
    filter: blur(6px);
    transition: all .2s; }
    @media only screen and (max-width: 640px) {
      .b-reviews__box {
        border-radius: 30px;
        padding: 35px 20px; } }
  .slick-active .b-reviews__box {
    opacity: 1;
    filter: blur(0); }
  .b-reviews__box-text {
    margin: -11px 0 29px;
    color: #8C8C8C;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: .04em; }
    @media only screen and (max-width: 640px) {
      .b-reviews__box-text {
        margin: -8px 0 21px;
        font-size: 14px;
        line-height: 28px; } }
    .b-reviews__box-text:last-child {
      margin-bottom: -11px; }
      @media only screen and (max-width: 640px) {
        .b-reviews__box-text:last-child {
          margin-bottom: -9px; } }
  .b-reviews__box-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -7px -10px 33px; }
    @media only screen and (max-width: 640px) {
      .b-reviews__box-details {
        flex-direction: column;
        margin-bottom: 20px; } }
    .b-reviews__box-details:last-child {
      margin-bottom: -7px; }
  .b-reviews__box-details-item {
    margin: 7px 10px; }
  .b-reviews__box-rating-inner {
    display: flex;
    flex-wrap: wrap;
    margin: -9px; }
    @media only screen and (max-width: 640px) {
      .b-reviews__box-rating-inner {
        margin: -6px; } }
  .b-reviews__box-rating-item {
    margin: 9px;
    line-height: 0; }
    @media only screen and (max-width: 640px) {
      .b-reviews__box-rating-item {
        margin: 6px; } }
    .b-reviews__box-rating-item::after {
      color: #E2E3EC;
      font-size: 20px; }
      @media only screen and (max-width: 640px) {
        .b-reviews__box-rating-item::after {
          font-size: 17px; } }
    .b-reviews__box-rating-item_active::after {
      color: #FFC224; }
  .b-reviews__box-company, .b-reviews__box-name, .b-reviews__box-site {
    margin: -7px 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .04em; }
  .b-reviews__box-company {
    margin-bottom: 10px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase; }
    .b-reviews__box-company:last-child {
      margin-bottom: -7px; }
  .b-reviews__box-name {
    color: #8C8C8C; }
  .b-reviews__box-site {
    display: block;
    color: #0F81EA;
    text-align: right; }

.b-section {
  padding: 200px 0 120px; }
  @media only screen and (max-width: 1230px) {
    .b-section {
      padding: 160px 0 100px; } }
  @media only screen and (max-width: 1023px) {
    .b-section {
      padding: 140px 0 100px; } }
  @media only screen and (max-width: 640px) {
    .b-section {
      padding: 100px 0 60px; } }
  .b-section_style_1 {
    background-image: url("../../media/img/b-section/bg_1.png");
    background-size: 211px 198px;
    background-position: calc( 50% - 679px) 267px;
    background-repeat: no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-section_style_1 {
        background-image: url("../../media/img/b-section/bg_1@2x.png"); } }
  .b-section_style_2, .b-section_style_3 {
    position: relative;
    padding-bottom: 190px;
    margin-bottom: -70px; }
    @media only screen and (max-width: 1230px) {
      .b-section_style_2, .b-section_style_3 {
        padding-bottom: 165px; } }
    @media only screen and (max-width: 640px) {
      .b-section_style_2, .b-section_style_3 {
        padding-bottom: 130px; } }
    @media only screen and (max-width: 1230px) {
      .b-section_style_2::before, .b-section_style_2::after, .b-section_style_3::before, .b-section_style_3::after {
        display: none; } }
    .b-section_style_2::before, .b-section_style_3::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 960px;
      background: linear-gradient(180deg, rgba(223, 226, 234, 0) 0%, rgba(223, 226, 234, 0.8) 100%); }
    .b-section_style_2::after, .b-section_style_3::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      background-image: url("../../media/img/b-section/bg_2.png"), url("../../media/img/b-section/bg_3.png"), url("../../media/img/b-section/bg_4.png"), url("../../media/img/b-section/bg_5.png"), url("../../media/img/b-section/bg_6.png"), url("../../media/img/b-section/bg_7.png"), url("../../media/img/b-section/bg_8.png"), url("../../media/img/b-section/bg_9.png");
      background-size: 174px 140px, 90px 90px, 87px 100px, 210px 172px, 162px 134px, 110px 105px, 59px 59px, 169px 74px;
      background-position: calc( 50% - 950px) 46px, calc( 50% + 678px) 0, calc( 50% - 636px) 139px, calc( 50% + 936px) 341px, calc( 50% - 454px) 577px, calc( 50% + 664px) 863px, calc( 50% - 671px) 998px, calc( 50% + 362px) 993px;
      background-repeat: no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-section_style_2::after, .b-section_style_3::after {
          background-image: url("../../media/img/b-section/bg_2@2x.png"), url("../../media/img/b-section/bg_3@2x.png"), url("../../media/img/b-section/bg_4@2x.png"), url("../../media/img/b-section/bg_5@2x.png"), url("../../media/img/b-section/bg_6@2x.png"), url("../../media/img/b-section/bg_7@2x.png"), url("../../media/img/b-section/bg_8@2x.png"), url("../../media/img/b-section/bg_9@2x.png"); } }
    .b-section_style_2 > *, .b-section_style_3 > * {
      position: relative;
      z-index: 1; }
  .b-section_style_2::after {
    top: 135px;
    height: calc( 100% - 135px); }
  .b-section_style_3::after {
    top: 385px;
    height: calc( 100% - 385px);
    background-position: calc( 50% - 950px) 46px, calc( 50% + 678px) 0, calc( 50% - 636px) 139px, calc( 50% + 936px) 341px, calc( 50% - 454px) 577px, calc( 50% + 664px) 863px, calc( 50% - 671px) 998px, calc( 50% + 362px) 1124px; }

.b-text-sm {
  margin: -8px 0 -9px;
  color: #000;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .04em; }
  .b-text-sm a {
    color: #1551ED; }
