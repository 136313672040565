.b-heading {
	display: flex; // to collapse margins inside
	flex-direction: column;
	margin-bottom: 70px;

	@include md-tablet_sm {
		margin-bottom: 60px; }

	@include md-mobile {
		margin-bottom: 45px; }

	&_m_b_lg {
		margin-bottom: 90px;

		@include md-tablet_sm {
			margin-bottom: 70px; }

		@include md-mobile {
			margin-bottom: 50px; } }

	&_m_b_sm {
		margin-bottom: 50px;

		@include md-tablet_sm {
			margin-bottom: 40px; }

		@include md-mobile {
			margin-bottom: 35px; } }

	&__title {
		margin: -9px 0 41px;

		color: $black;
		font-size: 60px;
		line-height: 60px;
		font-weight: 700;
		letter-spacing: .04em;

		text-align: center;
		text-shadow: -1px 1px 1px rgba( $white, .5 );

		@include md-tablet_sm {
			margin: -9px 0 35px;
			font-size: 50px;
			line-height: 54px; }

		@include md-mobile {
			margin: -9px 0 26px;
			font-size: 40px;
			line-height: 46px; }

		&_last {
			margin-bottom: -9px;

			@include md-tablet_sm {
				margin-bottom: -10px; }

			@include md-mobile {
				margin-bottom: -9px; } } }

	&__text {
		margin: -12px 0 -13px;

		color: $grey-dark;
		font-size: 16px;
		line-height: 36px;
		letter-spacing: .04em;

		text-align: center;

		@include md-mobile {
			margin: -10px 0 -11px;
			font-size: 14px;
			line-height: 32px; } } }
