@mixin md-desk {

	@media only screen and ( min-width: $md-tablet + 1px ) {
		@content; } }

@mixin md-desk_max {

	@media only screen and ( min-width: $md-desk_sm ) {
		@content; } }

@mixin md-desk_md {

	@media only screen and ( max-width: $md-desk_md + 1px ) {
		@content; } }

@mixin md-desk_md_only {

	@media only screen and ( min-width: $md-desk_sm + 1px ) and (max-width: $md-desk_md ) {
		@content; } }

@mixin md-desk_sm {

	@media only screen and ( max-width: $md-desk_sm ) {
		@content; } }

@mixin md-desk_sm_only {

	@media only screen and ( min-width: $md-tablet + 1px ) and (max-width: $md-desk_sm ) {
		@content; } }

@mixin md-desk_md_and_min_only {

	@media only screen and ( min-width: $md-tablet + 1px ) and (max-width: $md-desk_md ) {
		@content; } }

@mixin md-tablet {

	@media only screen and ( max-width: $md-tablet ) {
		@content; } }

@mixin md-tablet_only {

	@media only screen and ( min-width: $md-mobile + 1px ) and (max-width: $md-tablet ) {
		@content; } }

@mixin md-mobile {

	@media only screen and ( max-width: $md-mobile ) {
		@content; } }

@mixin md-mobile_sm {

	@media only screen and ( max-width: $md-mobile_sm ) {
		@content; } }

@mixin md-ie_10-plus {

	@media screen and ( -ms-high-contrast: active ), (-ms-high-contrast: none ) {
		@content; } }

@mixin md-ie_9-plus {

	@media screen and ( min-width: #{0\0} ) {
		@content; } }

@mixin md-print {

	@media print {
		@content; } }

@mixin md-retina {

	@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min--moz-device-pixel-ratio: 2 ), only screen and ( -o-min-device-pixel-ratio: 2/1 ), only screen and ( min-device-pixel-ratio: 2 ), only screen and ( min-resolution: 192dpi ), only screen and ( min-resolution: 2dppx ) {
		@content; } }
