@if $_use_forms == true {

	.-ol-input,
	.-ol-textarea {
		display: block;

		width: 100%;

		border: $brd_w-input solid $brd_c-input;
		border-radius: $brd_rd-input;
		padding: $p_vert-input $p_horiz-input;

		background: $bg-input;

		color: $c-input;
		font-size: $f_sz-input;
		line-height: $h-input - $brd_w-input * 2 - $p_vert-input * 2;
		letter-spacing: $l_s-input;

		text-overflow: ellipsis;

		@include placeholder-style;

		&[readonly],
		&[disabled] {
			color: $c-input_disabled;
			cursor: default; } }

	.-ol-input {
		height: $h-input; }

	.-ol-textarea {
		height: $h-textarea;
		resize: vertical;
		overflow: auto; }

	.-ol-input-file {
		position: relative;
		overflow: hidden;
		width: 100%;

		input {
			$h-input-file: 100%;

			width: 100%;
			height: $h-input-file;

			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;

			opacity: 0;

			cursor: pointer; } }

	.-ol-label { // tag label
		display: flex; // to collapse margins inside
		flex-direction: column;
		margin-bottom: $m_b-label;

		&_last {
			margin-bottom: 0; } }

	.-ol-label-text { // tag span
		display: block;

		color: $c-label;
		font-size: $f_sz-label;
		font-weight: $f_w-label; }

	.-ol-input-wrap {
		position: relative;

		&_disabled {
			opacity: .6;

			.-ol-input,
			.-ol-textarea {
				color: $c-placeholder; }

			label {
				cursor: auto; } }

		&_error {

			.-ol-input,
			.-ol-textarea {
				border-color: $brd_c-form-error;
				color: $c-form-error; } } }

	.-ol-row-input {
		margin-bottom: $m-row-input;

		&_last {
			margin-bottom: 0; } }

	.-ol-form-body {
		margin-bottom: $m-form-body;

		&_last {
			margin-bottom: 0; } } }
