.b-heading-2 {
	margin: -8px 0 36px;

	color: $black;
	font-size: 22px;
	line-height: 32px;
	font-weight: 700;
	letter-spacing: .06em;

	text-transform: uppercase;

	@include md-mobile {
		margin: -7px 0 28px;
		font-size: 20px;
		line-height: 28px; }

	&_last {
		margin-bottom: -9px;

		@include md-mobile {
			margin-bottom: -7px; } } }
