.i-loudspeaker,
.i-menu,
.i-arrow-3,
.i-plus,
.i-star,
.i-cross,
.i-logo,
.i-support,
.i-skype,
.i-telegram,
.i-account,
.i-attachment,
.i-calendar,
.i-logout,
.i-payments,
.i-refferals,
.i-stats,
.i-arrow,
.i-arrow-2,
.i-brush,
.i-chart,
.i-chat,
.i-like,
.i-transparent,
.i-wallet,
.i-envelope {

	@include font-sprite; }

.i-loudspeaker {

	&::after {
		content: '\e919'; } }

.i-menu {

	&::after {
		content: '\e913'; } }

.i-arrow-3 {

	&::after {
		content: '\e917'; } }

.i-plus {

	&::after {
		content: '\e916'; } }

.i-star {

	&::after {
		content: '\e915'; } }

.i-cross {

	&::after {
		content: '\e918'; } }

.i-logo {

	&::after {
		content: '\e914'; } }

.i-support {

	&::after {
		content: '\e900'; } }

.i-skype {

	&::after {
		content: '\e901'; } }

.i-telegram {

	&::after {
		content: '\e902'; } }

.i-account {

	&::after {
		content: '\e903'; } }

.i-attachment {

	&::after {
		content: '\e904'; } }

.i-calendar {

	&::after {
		content: '\e905'; } }

.i-logout {

	&::after {
		content: '\e906'; } }

.i-payments {

	&::after {
		content: '\e907'; } }

.i-refferals {

	&::after {
		content: '\e908'; } }

.i-stats {

	&::after {
		content: '\e909'; } }

.i-arrow {

	&::after {
		content: '\e90a'; } }

.i-arrow-2 {

	&::after {
		content: '\e90b'; } }

.i-brush {

	&::after {
		content: '\e90c'; } }

.i-chart {

	&::after {
		content: '\e90d'; } }

.i-chat {

	&::after {
		content: '\e90e'; } }

.i-like {

	&::after {
		content: '\e90f'; } }

.i-transparent {

	&::after {
		content: '\e910'; } }

.i-wallet {

	&::after {
		content: '\e911'; } }

.i-envelope {

	&::after {
		content: '\e912'; } }
