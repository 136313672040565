.b-content {
	$m_t-h2:				60px;
	$m_t-h2_inner:			9px;
	$m_b-h2:				35px;
	$m_b-h2_inner:			8px;

	$m_t-h3:				60px;
	$m_t-h3_sm:				40px;
	$m_t-h3_inner:			9px;
	$m_b-h3:				35px;
	$m_b-h3_inner:			7px;

	$m_t-p:					35px;
	$m_t-p_mb:				30px;
	$m_t-p_inner:			10px;
	$m_b-p:					50px;
	$m_b-p_mb:				40px;
	$m_b-p_inner:			11px;

	$m_t-ul:				40px;
	$m_b-ul:				40px;

	$m_t-img:				40px;
	$m_b-img:				40px;

	display: flex;
	flex-direction: column;
	align-items: start; // to don't stretch images and other tags if it less than 100%

	&_sm {
		max-width: 770px;
		margin: 0 auto; }

	h2 {
		margin: ( $m_t-h2 - $m_t-h2_inner ) 0 ( $m_b-h2 - $m_b-h2_inner );

		color: $black;
		font-size: 24px;
		line-height: 34px;
		font-weight: 500;
		letter-spacing: .06em;

		&:first-child {
			margin-top: -$m_t-h2_inner; }

		&:last-child {
			margin-bottom: -$m_b-h2_inner; }

		+ h3 {

			@if $m_t-h3_sm > $m_b-h2 {
				margin-top: ( $m_t-h3_sm - $m_t-h3_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ p {

			@if $m_t-p > $m_b-h2 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner );

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } } }

		+ img,
		+ video {

			@if $m_t-img > $m_b-h2 {
				margin-top: ( $m_t-img - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-h2 {
				margin-top: ( $m_t-ul - $m_b-h2_inner ) - ( $m_b-h2 - $m_b-h2_inner ); } } }

	h3 {
		margin: ( $m_t-h3 - $m_t-h3_inner ) 0 ( $m_b-h3 - $m_b-h3_inner );

		color: $black;
		font-size: 20px;
		line-height: 30px;
		font-weight: 500;
		letter-spacing: .04em;


		&:first-child {
			margin-top: -$m_t-h3_inner; }

		&:last-child {
			margin-bottom: -$m_b-h3_inner; }

		+ h2 {

			@if $m_t-h2 > $m_b-h3 {
				margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } }

		+ p {

			@if $m_t-p > $m_b-h3 {
				margin-top: ( $m_t-p - $m_t-p_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner );

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

		+ img,
		+ video {

			@if $m_t-img > $m_b-h3 {
				margin-top: ( $m_t-img - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-h3 {
				margin-top: ( $m_t-ul - $m_b-h3_inner ) - ( $m_b-h3 - $m_b-h3_inner ); } } }

	p {
		margin: -$m_t-p_inner 0 ( $m_b-p - $m_b-p_inner );

		@include md-mobile {
			margin: -$m_t-p_inner 0 ( $m_b-p_mb - $m_b-p_inner ); }

		color: $grey-dark;
		font-size: 15px;
		line-height: 32px;
		letter-spacing: .04em;

		text-align: justify;

		&:last-child {
			margin-bottom: -$m_b-p_inner; }

		+ h2 {

			@if $m_t-h2 > $m_b-p {
				margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-h2 - $m_t-h2_inner - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ h3 {

			@if $m_t-h3 > $m_b-p {
				margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-h3 - $m_t-h3_inner - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ img,
		+ video {

			@if $m_t-img > $m_b-p {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-p {
				margin-top: $m_t-ul - $m_b-p;

				@include md-mobile {
					margin-top: $m_t-ul - $m_b-p_mb; } } } }

	a {

		@include link-variant; }

	b {
		line-height: 0; // to fix bold alignment
		color: $black;
		font-weight: 700; }

	i {
		font-style: italic; }

	ul,
	ol {
		margin-bottom: $m_b-ul;

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 > $m_b-ul {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-ul; } }

		+ h3 {

			@if $m_t-h3 > $m_b-ul {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-ul; } }

		+ p {

			@if $m_t-p > $m_b-ul {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-ul;

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner ) - $m_b-ul; } } }

		+ img,
		+ video {

			@if $m_t-img > $m_b-ul {
				margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p - $m_b-p_inner );

				@include md-mobile {
					margin-top: ( $m_t-img - $m_b-p_inner ) - ( $m_b-p_mb - $m_b-p_inner ); } } } }

	ol {
		counter-reset: content-list; }

	li {
		position: relative;

		display: flex;
		flex-direction: column;

		margin-bottom: 40px;
		padding-left: 30px;

		&:last-child {
			margin-bottom: 0; } }

	ul li {

		&::before {
			$d: 9px;

			content: '';

			position: absolute;
			top: 1px;
			left: 0;

			width: $d;
			height: $d;

			border-radius: 50%;

			border: 2px solid $brand-danger; }

		&:nth-child( 3n - 1 )::before {
			border-color: $brand-warning; }

		&:nth-child( 3n )::before {
			border-color: $brand-success; } }

	ol li {

		&::before {
			counter-increment: content-list;
			content: counter( content-list ) '.';

			position: absolute;
			top: -1px; // to align font-weight: 500 with font-weight: 300
			left: 0;

			margin: ( -$m_t-p_inner ) 0 ( -$m_b-p_inner );

			color: $grey;
			font-size: 15px;
			line-height: 30px;
			font-weight: 500;
			letter-spacing: .04em; } }

	img,
	video,
	iframe,
	.b-content__block,
	button {
		margin-bottom: $m_b-img;

		&:last-child {
			margin-bottom: 0; }

		+ h2 {

			@if $m_t-h2 > $m_b-img {
				margin-top: ( $m_t-h2 - $m_t-h2_inner ) - $m_b-img; } }

		+ h3 {

			@if $m_t-h3 > $m_b-img {
				margin-top: ( $m_t-h3 - $m_t-h3_inner ) - $m_b-img; } }

		+ p {

			@if $m_t-p > $m_b-img {
				margin-top: ( $m_t-p - $m_t-p_inner ) - $m_b-img;

				@include md-mobile {
					margin-top: ( $m_t-p_mb - $m_t-p_inner ) - $m_b-img; } } }

		+ ul,
		+ ol {

			@if $m_t-ul > $m_b-img {
				margin-top: $m_t-ul - $m_b-img; } } }

	video,
	iframe,
	.b-content__block {
		display: block;
		max-width: 100%; }

	table {
		width: 100%;
		text-align: left; }

	tr {
		border-bottom-width: $brd_w color $table_hr; }

	td,
	th {
		padding: $p_vert-td $p_horiz-td;
		vertical-align: middle;

		&:first-child {
			padding-left: 0; }

		&:last-child {
			padding-right: 0; } }

	thead {

		th {
			font-size: 1.1em;
			font-weight: 600; } }

	form {
		width: 580px;
		max-width: 100%; } }

