.-ol-btn {
	display: inline-block;
	vertical-align: middle;

	margin-bottom: 0; // For input.-ol-btn
	border-style: solid;

	background-image: none; // Reset unusual Firefox-on-Android default style see https://github.com/necolas/normalize.css/issues/214

	font-weight: $f_w-btn;
	letter-spacing: $l_s-btn;

	text-align: center;

	cursor: pointer;
	touch-action: manipulation;

	transition: all .2s;

	@include btn-size( $h-btn, $brd_rd-btn, $p_vert-btn, $p_horiz-btn, $f_sz-btn, $lh-btn, $brd_w-btn );

	@include btn-variant( $c-btn-filled, $bg_c-btn-primary );

	box-shadow: -5px -5px 20px rgba( $white, .3 ), 5px 5px 15px rgba( $white, .15 ), inset 2px 2px 0px rgba( $white, .35 );

	&:hover {
		box-shadow: -5px -5px 20px rgba( $white, .2 ), 5px 5px 15px rgba( $white, .1 ), inset 1px 1px 0px rgba( $white, .35 ); }

	&:active {
		box-shadow: -5px -5px 20px rgba( $white, .2 ), 5px 5px 15px rgba( $white, .1 ), inset 1px 1px 0px rgba( $white, .15 ); }

	&_style_2 {
		box-shadow: -5px -5px 20px rgba( $white, .15 ), 5px 5px 15px rgba( $white, .1 ), inset 2px 2px 0 rgba( $white, .35 );

		&:hover {
			box-shadow: -5px -5px 20px rgba( $white, .05 ), 5px 5px 15px rgba( $white, .05 ), inset 1px 1px 0px rgba( $white, .35 ); }

		&:active {
			box-shadow: -5px -5px 20px rgba( $white, .05 ), 5px 5px 15px rgba( $white, .05 ), inset 1px 1px 0px rgba( $white, .15 ); } }

	&_default {

		@include btn-variant( $c-btn-default, $bg_c-btn-default ); }

	&_success {

		@include btn-variant( $c-btn-filled, $bg_c-btn-success ); }

	&_info {

		@include btn-variant( $c-btn-filled, $bg_c-btn-info ); }

	&_warning {

		@include btn-variant( $c-btn-filled, $bg_c-btn-warning ); }

	&_danger {

		@include btn-variant( $c-btn-filled, $bg_c-btn-danger ); }

	&_outline {

		@include btn-variant( $c-btn-outline, $bg_c-btn-outline, $brd_c-btn: $c-btn-outline ); }

	&[disabled] {

		@include btn-variant( $c-btn-disabled, $bg_c-btn-disabled, 0, 0 );

		cursor: not-allowed;
		pointer-events: none; } // Future-proof disabling of hover, active, click

	&_link {
		width: auto;
		height: auto;

		border: 0;
		border-radius: 0;
		padding: 0;

		@include btn-link( $brd-c-btn-link, $c-btn-link, $brd-c-btn-link_hover, $c-btn-link_hover ); }


	// Button Sizes
	// --------------------------------------------------

	&_lg {

		@include btn-size( $h-btn_lg, $brd_rd-btn_lg, $p_vert-btn_lg, $p_horiz-btn_lg, $f_sz-btn_lg, $lh-btn_lg, $brd_w-btn ); }

	&_sm {

		@include btn-size( $h-btn_sm, $brd_rd-btn_sm, $p_vert-btn_sm, $p_horiz-btn_sm, $f_sz-btn_sm, $lh-btn_sm, $brd_w-btn );

		padding-top: floor( $p_vert-btn_sm ) + 1;
		padding-bottom: ceil( $p_vert-btn_sm ) - 1; }

	&_xs {

		@include btn-size( $h-btn_xs, $brd_rd-btn_xs, $p_vert-btn_xs, $p_horiz-btn_xs, $f_sz-btn_xs, $lh-btn_xs, $brd_w-btn ); }

	&_w_fix_sm {
		width: 140px;
		max-width: 100%; }

	&_w_fix_lg {
		width: 180px;
		max-width: 100%; }

	&_min_w_initial {
		min-width: 0 !important; }


	// Button Styles
	// --------------------------------------------------

	&_next {
		min-width: 180px;

		padding-left: 20px;
		padding-right: 20px;

		text-align: center; }

	&_next &__text {
		position: relative; // for &::before and &::after

		padding-right: 45px;

		font-size: 14px;
		letter-spacing: .06em;

		@extend .i-arrow;

		&::before {
			content: '';

			position: absolute;
			top: -7px;
			right: 0;

			width: 30px;
			height: 30px;

			border-radius: 50%;

			background: rgba( $white, .15 );
			box-shadow: -5px -5px 20px rgba( $white, .3 ), 5px 5px 15px rgba( $black, .15 ), inset 1px 1px 0px rgba( $white, .15 );

			transition: all .2s; }

		&::after {
			position: absolute;
			top: 5px;
			right: 8px;

			color: $white;
			font-size: 8px;

			transition: right .2s; } }

	&_next:hover &__text {

		&::before {
			right: -3px;
			background: rgba( $white, .2 );
			box-shadow: -5px -5px 20px rgba( $white, .3 ), 5px 5px 15px rgba( $black, .15 ), inset 1px 1px 0px rgba( $white, .15 ); }

		&::after {
			right: 5px; } }

	&_next:active &__text {

		&::before {
			right: 0; }

		&::after {
			right: 9px; } } }
