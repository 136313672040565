.b-advantages {
	position: relative; // for &::before

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-bottom: 140px;

	@include md-tablet {
		margin-bottom: 80px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&::before {
		content: '';

		position: absolute;
		top: -54px;
		left: 0;

		width: 100%;
		height: 630px;

		background-image:  url( '#{$img}/b-advantages/bg_1.png' ), url( '#{$img}/b-advantages/bg_2.png' ), url( '#{$img}/b-advantages/bg_3.png' ), url( '#{$img}/b-advantages/bg_4.png' );
		background-size: 90px 90px, 210px 172px, 145px 50px, 59px 59px;
		background-position: calc( 50% + 575px ) 0px, calc( 50% + 932px ) 11px, -45px 357px, 93px 571px;
		background-repeat: no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-advantages/bg_1@2x.png' ), url( '#{$img}/b-advantages/bg_2@2x.png' ), url( '#{$img}/b-advantages/bg_3@2x.png' ), url( '#{$img}/b-advantages/bg_4@2x.png' ); }

		@include md-desk_lg {
			height: 720px;
			background-position: calc( 50% + 575px ) 0px, calc( 50% + 932px ) 11px, -45px 357px, 58px 641px; }

		@include md-desk_md {
			background-image:  url( '#{$img}/b-advantages/bg_1.png' ), url( '#{$img}/b-advantages/bg_4.png' );
			background-size: 90px 90px, 59px 59px;
			background-position: calc( 50% + 575px ) 0px, 28px 661px;
			background-repeat: no-repeat;

			@include md-retina {
				background-image: url( '#{$img}/b-advantages/bg_1@2x.png' ), url( '#{$img}/b-advantages/bg_4@2x.png' ); } }

		@include md-tablet {
			background: none; } }

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: -30px -15px;

		@include md-mobile {
			margin: -20px -10px; } }

	&__list-item {
		width: 33.3333%;
		min-width: 360px;
		padding: 30px 15px;

		@include md-mobile {
			width: 100%;
			min-width: 0;
			max-width: 360px;

			padding: 20px 10px; } }

	&__list-heading {
		position: relative; // for &::after

		display: flex;
		align-items: center;

		margin-bottom: 40px;

		@include md-mobile {
			margin-bottom: 25px; }

		&_wallet {

			@extend .i-wallet;

			&::after {
				top: 21px;
				left: 22px;
				font-size: 27px; } }

		&_transparent {

			@extend .i-transparent;

			&::after {
				top: 20px;
				left: 20px;
				font-size: 30px; } }

		&_brush {

			@extend .i-brush;

			&::after {
				top: 19px;
				left: 20px;
				font-size: 30px; } }

		&_like {

			@extend .i-like;

			&::after {
				top: 21px;
				left: 20px;
				font-size: 26px; } }

		&_chart {

			@extend .i-chart;

			&::after {
				top: 21px;
				left: 17px;
				font-size: 28px; } }

		&_chat {

			@extend .i-chat;

			&::after {
				top: 22px;
				left: 19px;
				font-size: 27px; } }

		&_last {
			margin-bottom: -7px; }

		&::before {
			content: '';

			display: block;
			flex-shrink: 0;

			width: 70px;
			height: 70px;

			margin-right: 30px;
			border-radius: 35px;

			background: $white;
			box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( $b_sh_c, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -5px -5px 10px rgba( $b_sh_c_inner, .25 );

			@include md-mobile {
				margin-right: 20px; } }

		&::after {
			position: absolute;
			color: $black; } }

	&__list-title {
		margin: -7px 0;

		color: $black;
		font-size: 20px;
		line-height: 28px;
		font-weight: 600;
		letter-spacing: .02em;

		@include md-mobile {
			margin: -6px 0 -7px;
			font-size: 18px;
			line-height: 26px; } }

	&__list-text {
		margin: -9px 0 -10px;

		color: $grey-light;
		font-size: 15px;
		line-height: 30px;
		letter-spacing: .04em; } }
