.b-reviews {
	position: relative; // for &::before and &::after
	padding-bottom: 190px;
	margin-bottom: -70px;

	@include md-tablet_sm {
		padding-bottom: 170px; }

	@include md-mobile {
		padding-bottom: 115px;
		text-align: center; }

	&_last {
		margin-bottom: 0; }

	&::before {
		content: '';

		position: absolute;
		left: 0;
		bottom: 0;

		width: 100%;
		height: 800px;

		background: linear-gradient( 180deg, rgba( $grey-lightest, 0 ) 0%, rgba( $grey-lightest, .8 ) 100% ); }

	&::after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: url( '#{$img}/b-reviews/bg_1.png' ), url( '#{$img}/b-reviews/bg_2.png' ), url( '#{$img}/b-reviews/bg_3.png' );
		background-size: 87px 100px, 90px 90px, 169px 74px;
		background-position: calc( 50% - 898px ) 111px, calc( 50% + 575px ) 428px, calc( 50% - 468px ) 475px;
		background-repeat: no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-reviews/bg_1@2x.png' ), url( '#{$img}/b-reviews/bg_2@2x.png' ), url( '#{$img}/b-reviews/bg_3@2x.png' ); }

		@include md-mobile {
			display: none; } }

	> * {
		position: relative;
		z-index: 1; } // to be over &::before and &::after

	&__list {
		display: flex;
		justify-content: center; }

	&__list-item {
		margin: 0 25px;

		@include md-tablet_sm {
			margin: 0 15px; } }

	&__box {
		opacity: .6;

		width: 520px;
		max-width: calc( 100vw - 60px );
		min-height: 355px;

		border-radius: 40px;
		padding: 40px 30px;

		background: $white;
		box-shadow: 0px 15px 30px rgba( $black, .1 ), inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 30px rgba( $b_sh_c_inner, .25 );

		filter: blur( 6px );

		transition: all .2s;

		@include md-mobile {
			border-radius: 30px;
			padding: 35px 20px; } }

	.slick-active &__box {
		opacity: 1;
		filter: blur( 0 ); }

	&__box-text {
		margin: -11px 0 29px;

		color: $grey-light;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: .04em;

		@include md-mobile {
			margin: -8px 0 21px;
			font-size: 14px;
			line-height: 28px; }

		&:last-child {
			margin-bottom: -11px;

			@include md-mobile {
				margin-bottom: -9px; } } }

	&__box-details {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		margin: -7px -10px 33px;

		@include md-mobile {
			flex-direction: column;
			margin-bottom: 20px; }

		&:last-child {
			margin-bottom: -7px; } }

	&__box-details-item {
		margin: 7px 10px; }

	&__box-rating {}

	&__box-rating-inner {
		display: flex;
		flex-wrap: wrap;
		margin: -9px;

		@include md-mobile {
			margin: -6px; } }

	&__box-rating-item {
		margin: 9px;
		line-height: 0;

		@include md-mobile {
			margin: 6px; }

		@extend .i-star;

		&::after {
			color: $grey-lighter;
			font-size: 20px;

			@include md-mobile {
				font-size: 17px; } }

		&_active::after {
			color: $brand-warning; } }

	&__box-company,
	&__box-name,
	&__box-site {
		margin: -7px 0;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: .04em; }

	&__box-company {
		margin-bottom: 10px;

		color: $black;
		font-weight: 600;

		text-transform: uppercase;

		&:last-child {
			margin-bottom: -7px; } }

	&__box-name {
		color: $grey-light; }

	&__box-site { // tag a
		display: block;
		color: $c-link;
		text-align: right; } }
