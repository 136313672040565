@if $_use_float == true {

	.-ol-clfx {

		&::before,
		&::after {
			content: "";
			display: table; }

		&::after {
			clear: both; } } }
