/*----------- main -----------*/

$black:							#000;
$grey-base:						$black;
$grey-darker:					lighten( $grey-base, 13.5% ); // #222
$grey-dark:						#2F3238; // 22%
$grey:							lighten( $grey-base, 33.5% ); // #555
$grey-light:					#8C8C8C; // 55%
$grey-lighter:					#E2E3EC; // 93%
$grey-lightest:					#DFE2EA; // 92%
$white:							#fff;

$brand-primary:					#1551ED; // blue
$brand-success:					#58c067; // green
$brand-info:					#69c1df; // cyan
$brand-warning:					#FFC224; // yellow
$brand-danger:					#FF383B; // red

/*----------- main (end) -----------*/





/*----------- typography -----------*/

$c-text-base:					$black;
$c-h:							$black;

/*----------- typography (end) -----------*/





/*----------- common -----------*/

$bg_c-body:						$white;
$brd_c:							$grey-lighter;

$b_sh_c:						#155167;
$b_sh_c_inner:					#798D94;

$bg_overlay:					rgba( $grey-darker, .6 );

/*----------- common (end) -----------*/





/*----------- link -----------*/

$c-link:							#0F81EA;
$brd_c-link:						transparent;
$c-link_hover:						darken( $c-link, 10% );
$brd_c-link_hover:					$c-link_hover;

/*----------- link (end) -----------*/





/*----------- form -----------*/

$c-form-error: 						$brand-danger;
$c-label: 							$grey-dark;

$bg-input: 							transparent;
$brd_c-input: 						$brd_c;

$c-input: 							$grey-dark;
$c-input_disabled:					$grey-light;

$c-placeholder:						$grey-dark;

$brd_c-form-error:					$c-form-error;

$bg-checkbox: 						$white;
$brd_c-checkbox: 					$brd_c;
$bg-checkbox_checked:				$white;
$brd_c-checkbox_checked:			$brand-primary;

$bg-radio: 							$white;
$brd_c-radio: 						$grey-lighter;
$bg-radio_checked:					$white;
$brd_c-radio_checked:				$brand-primary;

/*----------- form (end) -----------*/





/*----------- table -----------*/

$table_even:						darken( $white, 5% );
$table_odd:							darken( $white, 5% );

$table_hr:							darken( $white, 8% );

/*----------- table (end) -----------*/





/*----------- button -----------*/

$c-btn-default:						$brand-primary;
$c-btn-filled:						$white;
$c-btn-disabled:					$white;
$c-btn-outline:						$brand-primary;

$c-btn-link:						$c-link;
$c-btn-link_hover:					$c-btn-link;

$bg_c-btn-default:					$white;
$bg_c-btn-primary:					$black;
$bg_c-btn-success:					$brand-success;
$bg_c-btn-info:						$brand-info;
$bg_c-btn-warning:					$brand-warning;
$bg_c-btn-danger:					$brand-danger;
$bg_c-btn-disabled:					darken( $white, 20% );
$bg_c-btn-outline:					transparent;

$brd-c-btn-link:					transparent;
$brd-c-btn-link_hover:				fadeout( $c-btn-link, 20% );

/*----------- button (end) -----------*/
