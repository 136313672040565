.l-cell {

	@include cell;

	&__item {

		&_middle {
			vertical-align: middle; }

		&_top_mb {

			@include md-mobile {
				vertical-align: top; } } } }
