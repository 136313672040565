.c-slick-reviews {

	@extend .c-slick;

	display: flex !important; // to set js

	.slick-list,
	.slick-track {
		display: flex;
		flex-grow: 1; }

	.slick-list {
		display: flex; // to collapse margins inside
		flex-direction: column;
		margin: -15px 0 -45px; }

	.slick-slide {
		padding: 15px 0 45px; }

	.slick-arrow {
		$d:		80px;
		$d_mb:	50px;

		position: absolute;
		top: calc( 50% - #{ $d/2 } );
		z-index: 9; // to be over .slick-list

		width: $d;
		height: $d;

		border-radius: 50%;

		background: $white;
		box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( $b_sh_c, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -5px -5px 10px rgba( $b_sh_c_inner, .25 );

		font-size: 0; // to set text inside

		@extend .i-arrow-3;

		@include md-tablet_xs {
			display: none !important; }

		&::after {
			position: absolute;
			top: 29px;

			color: $black;
			font-size: 20px;

			transition: all .2s; } }

	.slick-prev {
		right: calc( 50% + 285px );

		@include md-tablet_sm {
			right: calc( 50% + 275px ); }

		&::after {
			right: 36px;
			transform: rotateZ( 180deg ); }

		&:hover::after {
			right: 39px; } }

	.slick-next {
		left: calc( 50% + 285px );

		@include md-tablet_sm {
			left: calc( 50% + 275px ); }

		&::after {
			left: 36px; }

		&:hover::after {
			left: 39px; } } }
