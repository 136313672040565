.l-flex {
	display: flex;
	flex-wrap: wrap;

	&_nowrap {
		flex-wrap: nowrap; }

	&_valign_center {
		align-items: center; }

	&_valign_center_mb {

		@include md-mobile {
			align-items: center; } }

	&_valign_center_top_mb {
		align-items: center;

		@include md-mobile {
			align-items: flex-start; } }

	&_right {
		justify-content: flex-end; }

	&_center {
		justify-content: center; }

	&_space_between {
		justify-content: space-between; }

	&_space_around {
		justify-content: space-around; }

	&_col {
		flex-direction: column; }

	& > &__item {

		&_stretch {
			flex-grow: 1; }

		&_major {
			flex-shrink: 0; }

		&_equal_none_tb {

			@include md-tablet {
				flex: 0 1 auto; } } }

	&_equal {
		flex-wrap: nowrap; }

	&_equal > &__item {
		flex: 1 1 100%;
		overflow: hidden; }

	&_equal_with_width > &__item {
		// WARNING! It stops being equal if adding padding to the element
		// WARNING! Doesn't work correct with width: 0; in IE, please set it to an actual
		// WARNING! flex-shrink: 1; doesn't work correct
		flex-grow: 1;
		flex-basis: 0;
		width: 0; } } // don't remove to stay equal if there are elements with white-space: nowrap; inside
