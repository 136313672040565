.b-logos-bw {
	display: flex; // to collapse margins inside
	flex-direction: column;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin: -10px -20px; }

	&__item {
		margin: 10px 20px; }

	&__img {
		filter: grayscale( 100% );

		&_style_2 {
			margin-top: 5px; } } }
