@mixin md-desk_xl {

	@media only screen and ( min-width: $md-desk_xl ) {
		@content; } }

@mixin md-desk_lg {

	@media only screen and ( max-width: $md-desk_lg ) {
		@content; } }

@mixin md-tablet_sm {

	@media only screen and ( max-width: $md-tablet_sm ) {
		@content; } }

@mixin md-tablet_xs {

	@media only screen and ( max-width: $md-tablet_xs ) {
		@content; } }

@mixin md-mobile_md {

	@media only screen and ( max-width: $md-mobile_md ) {
		@content; } }

@mixin md-b-box_xl {

	@media only screen and ( max-width: $md-b-box_xl ) {
		@content; } }

@mixin md-b-box_lg {

	@media only screen and ( max-width: $md-b-box_lg ) {
		@content; } }

@mixin md-b-box_md {

	@media only screen and ( max-width: $md-b-box_md ) {
		@content; } }

@mixin md-b-box_sm {

	@media only screen and ( max-width: $md-b-box_sm ) {
		@content; } }



@mixin md-b-box_states( $media: false, $class: '' ) {

	&_xl {

		@if $media {

			&#{$class} {

				@include md-b-box_xl {
					@content; } } }

		@else {
			@content; } }

	&_lg {

		@if $media {

			&#{$class} {

				@include md-b-box_lg {
					@content; } } }

		@else {
			@content; } }

	&_md {

		@if $media {

			&#{$class} {

				@include md-b-box_md {
					@content; } } }

		@else {
			@content; } }

	&_sm {

		@if $media {

			&#{$class} {

				@include md-b-box_sm {
					@content; } } }

		@else {
			@content; } } }
