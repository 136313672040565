.b-box-contact {
	$brd_rd: 25px;

	position: relative; // for &::before and &::after
	padding: 60px 50px 45px;

	@include md-tablet {
		padding: 50px 40px 45px; }

	@include md-mobile {
		padding: 0; }

	&::before,
	&::after {
		content: '';

		position: absolute;

		border-radius: $brd_rd;

		background: $white;

		@include md-mobile {
			display: none; } }

	&::before {
		top: 30px;
		left: 30px;

		width: calc( 100% - 60px );
		height: calc( 100% - 60px );

		box-shadow: 0px 25px 70px rgba( $black, .1 ); }

	&::after {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		box-shadow: inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 20px rgba( $b_sh_c_inner, .13 ); }

	&__inner {
		position: relative;
		z-index: 1; } // to be over &&::before and &&::after

	&__form {
		margin-bottom: 40px;
		border-bottom: $brd_w solid $brd_c;
		padding-bottom: 40px; }

	&__info {}

	&__list {}

	&__list-item {
		position: relative;

		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 30px;
		padding-left: 50px;

		&:last-child {
			margin-bottom: 0; }

		&::after {
			position: absolute;
			color: $black; }

		&_email {

			@extend .i-envelope;

			&::after {
				top: -2px;
				left: 7px;
				font-size: 14px; } }

		&_skype {

			@extend .i-skype;

			&::after {
				top: -5px;
				left: 6px;
				font-size: 20px; } }

		&_telegram {

			@extend .i-telegram;

			&::after {
				top: -4px;
				left: 4px;
				font-size: 18px; } } }

	&__list-text {
		margin: -7px 0;

		color: $grey-light;
		font-size: 14px;
		line-height: 24px;
		font-weight: 500;
		letter-spacing: .04em; } }
