@mixin e-headings {

	.-ol-content h1,
	.-ol-h1,
	.-ol-content h2,
	.-ol-h2,
	.-ol-content h3,
	.-ol-h3,
	.-ol-content h4,
	.-ol-h4,
	.-ol-content h5,
	.-ol-h5,
	.-ol-content h6,
	.-ol-h6 {

		@content; } }

@mixin e-h1 {

	.-ol-content h1,
	.-ol-h1 {

		@content; } }

@mixin e-h2 {

	.-ol-content h2,
	.-ol-h2 {

		@content; } }

@mixin e-h3 {

	.-ol-content h3,
	.-ol-h3 {

		@content; } }

@mixin e-h4 {

	.-ol-content h4,
	.-ol-h4 {

		@content; } }

@mixin e-h5 {

	.-ol-content h5,
	.-ol-h5 {

		@content; } }

@mixin e-h6 {

	.-ol-content h6,
	.-ol-h6 {

		@content; } }

@mixin e-p {

	.-ol-content p,
	.-ol-p {

		@content; } }

@mixin e-b {

	.-ol-content b,
	.-ol-b {

		@content; } }

@mixin e-btns {

	.-ol-btn,
	.-ol-btn_link,
	.-ol-btn_outline,
	.-ol-btn_default,
	.-ol-btn_primary,
	.-ol-btn_success,
	.-ol-btn_info,
	.-ol-btn_warning,
	.-ol-btn_danger {

		@content; } }
