.b-list-favorites {
	max-width: 450px;

	&__item {
		position: relative; // for &::after

		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 40px;
		padding-left: 40px;

		@extend .i-star;

		&:last-child {
			margin-bottom: 0; }

		&::after {
			position: absolute;
			top: -5px;
			left: 0;

			color: $brand-warning;
			font-size: 20px; } }

	&__text {
		margin: -11px 0;

		color: $grey-light;
		font-size: 14px;
		line-height: 32px;
		font-weight: 500;
		letter-spacing: .04em; } }
