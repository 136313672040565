.b-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 20px 35px;

	background-color: rgba( $white, 0 );
	box-shadow: 0px 15px 50px rgba( $b_sh_c, 0 );

	transition: all .2s;

	@include md-tablet {
		min-height: 63px;
		padding: 20px; }

	&_active {
		padding: 10px 20px;
		background-color: rgba( $white, 1 );
		box-shadow: 0px 15px 50px rgba( $b_sh_c, .15 ); }

	&__item {}

	&__logo { // tag a

		@include center-tsf;

		@include md-tablet {
			position: static !important;
			transform: none; } }

	&__logo-img {
		height: 25px; }

	&__nav {
		display: flex; // to collapse margins inside
		flex-direction: column;
		padding-left: 15px; }

	&__nav-inner {
		display: flex; }

	&__nav-item {
		margin: 0 25px; }

	&__nav-text {
		margin: -5px 0 -7px;

		color: $black;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		letter-spacing: .06em; }

	&__language {
		display: flex;
		align-items: center;

		height: 41px;

		margin-bottom: 2px;
		border-radius: 7px;
		padding: 10px 15px 10px 10px;

		background: rgba( $white, .2 );
		box-shadow: inset 1px 1px 0px rgba( $white, .35 ), 5px 5px 20px rgba( $black, .1 ); }

	&__language-flag {
		position: relative;

		overflow: hidden;

		margin-right: 12px;
		border-radius: 3px;

		box-shadow: inset 1px 1px 0px rgba( $white, .35 ), 2px 2px 5px rgba( $black, .1 );

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			z-index: 1; // to be over &__btn-img

			width: 100%;
			height: 100%;

			border-radius: 3px;

			box-shadow: inset 1px 1px 0px rgba( $white, .35 ), 2px 2px 5px rgba( $black, .1 ); } }

	&__language-flag-img {
		height: 21px; }

	&__language-text {
		margin: -5px 0 -7px;

		color: $black;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: .06em; }

	&__options {
		display: flex; // to collapse margins inside
		flex-direction: column; }

	&__options-inner {
		display: flex;
		align-items: center;
		margin: 0 -20px; }

	&__options-item {
		margin: 0 20px;

		&_valign_b {
			padding-bottom: 2px; } }

	&__nav-hamburger {
		display: none;

		@include md-tablet {
			display: block; } }

	&__nav-hamburger-btn {

		@extend .i-menu;

		@include md-tablet {
			top: 23px;
			right: 23px; }

		&::after {
			display: block;
			color: $black;
			font-size: 17px; } }

	&__nav-popup {
		position: fixed;
		top: 0;
		right: -280px;
		z-index: $z_i-b-header-nav-mb;

		overflow: auto;

		width: 280px;
		height: 100vh;

		padding: 60px 30px 30px;

		background: $black;
		box-shadow: 0px 15px 30px rgba( $black, .1 );

		transition: right .2s;

		pointer-events: none; }

	&__nav-hamburger_active &__nav-popup {
		right: 0;
		pointer-events: auto; }

	&__nav-popup-cross {
		position: absolute;
		top: 24px;
		right: 24px;

		@extend .i-cross;

		&::after {
			display: block;

			color: $white;
			font-size: 15px;

			transform: rotate( 0deg );

			transition: transform .2s; }

		&:hover::after {
			transform: rotate( 90deg ); } }

	&__nav-popup-list {
		position: relative;
		z-index: 1;
		margin-bottom: 40px;

		&_last {
			margin-bottom: 0; } }

	&__nav-popup-list-item {
		display: flex;
		flex-direction: column;

		margin-bottom: 20px;
		border-bottom: 1px solid rgba( $white, .15 );
		padding-bottom: 20px;

		&:last-child {
			margin-bottom: 0; } }

	&__nav-popup-link { // tag a
		display: block;

		margin: -5px 0 -7px;

		color: $white;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		letter-spacing: .04em;

		transition: color .2s;

		&:hover,
		&_active {
			color: $brand-warning; } }

	&__nav-popup-item {
		margin-bottom: 20px;

		&_last {
			margin-bottom: 0; } } }

