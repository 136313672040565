.b-contacts {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	@include md-tablet_sm {
		flex-direction: column; }

	&__item {
		width: 50%;
		max-width: 570px;
		padding: 0 15px;

		@include md-tablet_sm {
			width: 100%;
			margin: 0 auto 70px; }

		&:last-child {

			@include md-tablet_sm {
				margin-bottom: 0; } } }

	&__section {
		margin-bottom: 75px;
		padding-left: 20px;

		@include md-tablet_sm {
			margin-bottom: 70px;
			padding-left: 0; }

		&_first {
			padding-top: 60px;

			@include md-tablet_sm {
				padding-top: 0; } }

		&:last-child {
			margin-bottom: 0; } } }
