.b-text-sm {
	margin: -8px 0 -9px;

	color: $black;
	font-size: 14px;
	line-height: 28px;
	letter-spacing: .04em;

	a {
		color: $brand-primary; } }
