.b-hero-img {
	max-width: 970px;
	margin: 0 auto 60px;

	@include md-tablet_sm {
		margin-bottom: 45px; }

	@include md-mobile {
		margin-bottom: 40px; }

	&_last {
		margin-bottom: 0; } }
