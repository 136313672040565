.accordionjs {

	&_last {}

	.acc_section {

		.acc_head {
			cursor: pointer;

			> * {

				&::before,
				&::after {
					content: '';
					position: absolute;
					background-color: $black; }

				&::before {
					top: 13px;
					left: -58px;

					width: 16px;
					height: 2px;

					@include md-mobile {
						top: 11px;
						left: -46px; } }

				&::after {
					top: 6px;
					left: -51px;

					height: 16px;
					width: 2px;

					transform: rotateZ( 0deg );

					transition: transform .2s;

					@include md-mobile {
						top: 4px;
						left: -39px; } } } }

		&.acc_active > .acc_head {

			> *::after {
				transform: rotateZ( 90deg ); } } } }
