.b-commission {
	position: relative; // for &::before

	margin-bottom: -30px;
	padding: 240px 0 410px;

	background: url( '#{$img}/b-commission/bg.png' ) center / $md-desk_xl 100% no-repeat;

	@include md-retina {
		background-image: url( '#{$img}/b-commission/bg@2x.png' ); }

	@include md-desk_xl {
		background-position: 100% 100%; }

	@include md-tablet {
		margin-bottom: -140px;
		padding: 220px 0 380px;
		text-align: center; }

	@include md-mobile {
		margin-bottom: -200px;
		padding: 85px 0 360px;
		background-position: 83% center; }

	&::before {
		content: '';

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image:  url( '#{$img}/b-commission/bg_1.png' ), url( '#{$img}/b-commission/bg_2.png' ), url( '#{$img}/b-commission/bg_3.png' ), url( '#{$img}/b-commission/bg_4.png' ), url( '#{$img}/b-commission/bg_5.png' );
		background-size: 162px 135px, 129px 107px, 55px 55px, 144px 110px, 108px 101px;
		background-position: calc( 50% - 717px ) 47px, calc( 50% + 764px ) 226px, 178px 733px, calc( 50% - 378px ) 847px, calc( 50% + 701px ) 989px;
		background-repeat: no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-commission/bg_1@2x.png' ), url( '#{$img}/b-commission/bg_2@2x.png' ), url( '#{$img}/b-commission/bg_3@2x.png' ), url( '#{$img}/b-commission/bg_4@2x.png' ), url( '#{$img}/b-commission/bg_5@2x.png' ); }

		@include md-mobile {
			display: none; } }

	&__list {
		display: flex; // to collapse margins inside
		flex-direction: column;
		margin-bottom: 30px;

		&_last {
			margin-bottom: 0; } }

	&__list-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: -15px;

		@include md-tablet_sm {
			margin: -10px; } }

	&__list-item {
		width: 25%;
		min-width: 300px;
		padding: 15px;

		@include md-tablet_sm {
			padding: 10px; }

		@include md-mobile_md {
			width: 100%;
			min-width: 0;
			max-width: 300px; } }

	&__list-box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		min-height: 180px;

		border-radius: 30px;
		padding: 30px 20px;

		background: $white;
		box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( $b_sh_c, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 30px rgba( $b_sh_c_inner, .25 );

		text-align: center;

		@include md-mobile {
			min-height: 160px;
			border-radius: 25px; }

		&_special {
			background: #FFE14D;
			box-shadow: -5px -5px 15px rgba( $white, .1 ), 7px 7px 15px rgba( 0, 0, 0, .15 ), inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 30px rgba( #665338, .2 ); } }

	&__list-box-item {}

	&__list-title {
		margin: -6px 0 -7px;

		color: $black;
		font-weight: 700;
		font-size: 22px;
		line-height: 28px;
		letter-spacing: .08em; }

	&__list-number {
		margin: -5px 0 10px;

		color: $black;
		font-size: 36px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: .04em;

		&_sm {
			font-size: 24px; }

		&_last {
			margin-bottom: -5px; } }

	&__text {
		margin: -7px 0 -8px;

		color: $black;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: .04em; } }
