.c-slick {

	&.slick-slider {
		position: relative;

		display: block;

		-webkit-touch-callout: none;
		user-select: none;

		touch-action: pan-y;

		-webkit-tap-highlight-color: transparent; }

	.slick-list,
	.slick-track {
		transform: translate3d(0, 0, 0); }

	&.slick-initialized {

		.slick-slide {
			display: block; } }

	&.slick-loading {

		.slick-slide,
		.slick-track {
			visibility: hidden; } }

	&.slick-vertical .slick-slide {
		display: block;
		height: auto; }

	.slick-list {
		position: relative;

		overflow: hidden;

		display: block;

		margin: 0;
		padding: 0;

		&:focus {
			outline: none; }

		&.dragging {
			cursor: pointer;
			cursor: hand; } }

	.slick-track {
		position: relative;
		left: 0;
		top: 0;

		display: block;

		&::before,
		&::after {
			content: "";
			display: table; }

		&::after {
			clear: both; } }

	.slick-slide {
		display: none;
		float: left;
		min-height: 1px;

		img {
			display: block; }

		&.slick-loading img {
			display: none; }

		&.dragging img {
			pointer-events: none; } }

	[dir="rtl"] & .slick-slide,
	[dir="rtl"] .slick-slide {
		float: right; }

	.slick-arrow.slick-hidden {
		display: none; } }
