.b-box {
	position: relative; // for &::before and &::after

	display: flex; // to collapse margins inside
	flex-direction: column;

	max-width: 100%;

	margin: $m_vert-b-box auto;

	@include md-tablet_sm {
		margin: $m_vert-b-box_tb auto; }

	&::before,
	&::after {
		content: '';

		position: absolute;

		border-radius: $brd_rd-b-box; }

	&::before {
		top: 50px;
		left: 50px;

		width: calc( 100% - 100px );
		height: calc( 100% - 50px );

		box-shadow: 0px 30px 90px rgba( $black, .2 ); }

	&::after {
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: $white;
		box-shadow: inset 1px 1px 0px rgba( $white, .5 ), inset -15px -15px 20px rgba( $b_sh_c_inner, .17 ); }

	&_without_outside_space_horiz {
		margin-left: 0;
		margin-right: 0; }

	&_without_outside_space_vert {
		margin-top: 0;
		margin-bottom: 0; }

	&_without_outside_space {
		margin: 0; }

	&_xl {
		width: $w-b-box_xl; }

	&_lg {
		width: $w-b-box_lg; }

	&_md {
		width: $w-b-box_md; }

	&_sm {
		width: $w-b-box_sm; }

	&_sm_mb {

		@include md-mobile {
			width: $w-b-box_sm; } }

	@include md-b-box_states( $media: true, $class: ':not(.b-box_with_outside_space_mb)' ) {
		margin: 0; }



	&__close {
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 2; // to be over &__body

		@extend .i-cross;

		@include md-mobile {
			top: 20px;
			right: 20px; }

		&::after {
			display: block;

			color: $black;
			font-size: 17px;

			transform: rotate( 0deg );

			transition: transform .2s; }

		&:hover::after {
			transform: rotate( 90deg ); } }



	&__body {
		position: relative;
		z-index: 1; // to be over &::before and &::after
		padding: $p_vert-b-box $p_horiz-b-box;

		@include md-tablet_sm {
			padding: $p_vert-b-box_tb $p_horiz-b-box_tb; }

		@include md-mobile {
			padding: $p_vert-b-box_mb $p_horiz-b-box_mb; }

		&_without_inside_space {
			padding: 0 !important; } // to set the style for all sizes of the box

		&_style_2,
		&_style_3 {

			&::before,
			&::after {
				content: '';

				position: absolute;
				z-index: 1;

				pointer-events: none; }

			&::before {
				width: 157px;
				height: 130px;

				background: url( '#{$img}/b-box/bg_1.png' ) center / contain no-repeat;

				@include md-retina {
					background-image: url( '#{$img}/b-box/bg_1@2x.png' ); } }

			&::after {
				width: 112px;
				height: 94px;

				background: url( '#{$img}/b-box/bg_2.png' ) center / contain no-repeat;

				@include md-retina {
					background-image: url( '#{$img}/b-box/bg_2@2x.png' ); } } }

		&_style_2 {

			&::before {
				top: -71px;
				right: 92px; }

			&::after {
				bottom: 12px;
				left: -70px; } }

		&_style_3 {

			&::before {
				top: 275px;
				right: -73px; }

			&::after {
				bottom: 202px;
				left: -70px; } } }

	&_xl &__body {

		@include md-b-box_xl {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_lg {
				padding: 0; } } }

	&_lg &__body {

		@include md-b-box_lg {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_lg {
				padding: 0; } } }

	&_md &__body {

		@include md-b-box_md {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_md {
				padding: 0; } } }

	&_sm &__body {

		@include md-b-box_sm {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_sm {
				padding: 0; } } } }
